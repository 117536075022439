import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import CompetitionService from "../services/competition-service";
import InputFileUpload from './upload-button-component';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import dayjs from 'dayjs';

export default class AddCompetitionDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.competition? props.competition.id: null,
      title: props.competition? props.competition.title:'',
      region: props.competition? props.competition.region:'',
      city: props.competition? props.competition.city:'',
      moreDays: props.competition && props.competition.dateEnd? true: false,
      labelDate: props.competition && props.competition.dateEnd? 'Data inizio': 'Data di svolgimento',
      date: props.competition? props.competition.date:'',
      dateEnd: props.competition? props.competition.dateEnd:'',
      registrationEnd: props.competition? props.competition.registrationEnd:'',
      unitCost: props.competition? props.competition.unitCost:'',
      coupleCost: props.competition? props.competition.coupleCost:'',
      groupCost: props.competition? props.competition.groupCost:'',
      secondUnitCost: props.competition? props.competition.secondUnitCost:'',
      secondCoupleCost: props.competition? props.competition.secondCoupleCost:'',
      secondGroupCost: props.competition? props.competition.secondGroupCost:'',
      thirdUnitCost: props.competition? props.competition.thirdUnitCost:'',
      thirdCoupleCost: props.competition? props.competition.thirdCoupleCost:'',
      thirdGroupCost: props.competition? props.competition.thirdGroupCost:'',
      fixedGroup: props.competition && props.competition.fixedGroupCost ? true:false,
      fixedGroupCost: props.competition? props.competition.fixedGroupCost:'',
      fixedGroupComponents: props.competition? props.competition.fixedGroupComponents:'',
      addFixedGroupCost: props.competition? props.competition.addFixedGroupCost:'',
      poster: props.competition? props.competition.poster:'',
      rules: props.competition? props.competition.rules:'',
      showSpinner: false,
      labelDialog: props.competition? 'Modifica': 'Crea'
    };
  }
  
  addCompetition = async e => {
    e.preventDefault();
    this.setState({showSpinner: true})
    var componentToCreate={
    	id: this.state.id,
      	title: this.state.title,
      	region: this.state.region,
        city: this.state.city,
        date: this.state.date,
        dateEnd: this.state.dateEnd,
        registrationEnd: this.state.registrationEnd,
        unitCost: this.state.unitCost,
        coupleCost: this.state.coupleCost,
        groupCost: !this.state.fixedGroup ? this.state.groupCost:'',
        secondUnitCost: this.state.secondUnitCost,
        secondCoupleCost: this.state.secondCoupleCost,
        secondGroupCost: !this.state.fixedGroup ? this.state.secondGroupCost:'',
        thirdUnitCost: this.state.thirdUnitCost,
        thirdCoupleCost: this.state.thirdCoupleCost,
        thirdGroupCost: !this.state.fixedGroup ? this.state.thirdGroupCost:'',
        fixedGroupCost: this.state.fixedGroup ? this.state.fixedGroupCost:'',
        fixedGroupComponents: this.state.fixedGroup ? this.state.fixedGroupComponents:'',
        addFixedGroupCost: this.state.fixedGroup ? this.state.addFixedGroupCost:'',
        poster: this.state.poster,
        rules: this.state.rules
    }
    CompetitionService.addCompetition(componentToCreate).then(
        response => {
            this.setState({showSpinner: false})
            this.props.refreshCompetitions();
    		this.props.handleClose();
        },
        error => {
          	this.setState({showSpinner: false});
          	this.props.refreshCompetitions();
    		this.props.handleClose();
        }
    );
  }

  clearPoster = () => {
  	this.setState({
  		poster: ''
  	})
  }

  clearRules = () => {
  	this.setState({
  		rules: ''
  	})
  }

  getFileFromUpload = (event) => {
  	var uploadedFileBase64 = event.target.files[0]
  	if(uploadedFileBase64){
	  	var filereader = new FileReader();
		filereader.readAsDataURL(uploadedFileBase64);
		const scope = this
		filereader.onload = function (evt) {
		    var base64 = evt.target.result;
	    	scope.setState({poster: base64})
		}
	}
  };

  getRulesFromUpload = (event) => {
  	var uploadedFileBase64 = event.target.files[0]
  	if(uploadedFileBase64){
	  	var filereader = new FileReader();
		filereader.readAsDataURL(uploadedFileBase64);
		const scope = this
		filereader.onload = function (evt) {
		    var base64 = evt.target.result;
	    	scope.setState({rules: base64})
		}
	}
  };
  
  render(){
	  return (
	      <Dialog
	        open={this.props.open}
	        aria-labelledby="alert-dialog-title"
	        aria-describedby="alert-dialog-description"
	      >
	        <DialogTitle id="alert-dialog-title">
	          <b>Crea Nuova Competizione</b>
	        </DialogTitle>
	        <DialogContent>
	          <TextField
	            autoFocus
	            margin="dense"
	            id="title"
	            label="Titolo"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.title}
	            onChange={ (e) =>{ this.setState({ title: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="region"
	            label="Regione"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.region}
	            onChange={ (e) =>{ this.setState({ region: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="city"
	            label="Città"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.city}
	            onChange={ (e) =>{ this.setState({ city: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="unitCost"
	            label="Quota di partecipazione singolo (€)"
	            type="number"
	            fullWidth
	            variant="standard"
	            value={this.state.unitCost}
	            onChange={ (e) =>{ this.setState({ unitCost: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="secondUnitCost"
	            label="Quota di partecipazione secondo singolo (€)"
	            type="number"
	            fullWidth
	            variant="standard"
	            value={this.state.secondUnitCost}
	            onChange={ (e) =>{ this.setState({ secondUnitCost: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="thirdUnitCost"
	            label="Quota di partecipazione terzo singolo (€)"
	            type="number"
	            fullWidth
	            variant="standard"
	            value={this.state.thirdUnitCost}
	            onChange={ (e) =>{ this.setState({ thirdUnitCost: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="coupleCost"
	            label="Quota di partecipazione doppio (€)"
	            type="number"
	            fullWidth
	            variant="standard"
	            value={this.state.coupleCost}
	            onChange={ (e) =>{ this.setState({ coupleCost: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="secondCoupleCost"
	            label="Quota di partecipazione secondo doppio (€)"
	            type="number"
	            fullWidth
	            variant="standard"
	            value={this.state.secondCoupleCost}
	            onChange={ (e) =>{ this.setState({ secondCoupleCost: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="thirdCoupleCost"
	            label="Quota di partecipazione terzo doppio (€)"
	            type="number"
	            fullWidth
	            variant="standard"
	            value={this.state.thirdCoupleCost}
	            onChange={ (e) =>{ this.setState({ thirdCoupleCost: e.target.value });}}
	          />
	          <FormControlLabel 
	          	control={<Checkbox
				  checked={this.state.fixedGroup}
				  onChange={(event) =>{ 
		          	this.setState({ 
		          		fixedGroup: event.target.checked
		          	});
		          }}
				  inputProps={{ 'aria-label': 'controlled' }}
			  		/>
			  	} 
			  	label="Quota fissa gruppo" />
			  { this.state.fixedGroup && (
			  	<div>
		          <TextField
		            margin="dense"
		            id="fixedGroupCost"
		            label="Quota di partecipazione gruppo (€)"
		            type="number"
		            fullWidth
		            variant="standard"
		            value={this.state.fixedGroupCost}
		            onChange={ (e) =>{ this.setState({ fixedGroupCost: e.target.value });}}
		          />
		          <TextField
		            margin="dense"
		            id="fixedGroupComponents"
		            label="Numero componenti gruppo"
		            type="number"
		            fullWidth
		            variant="standard"
		            value={this.state.fixedGroupComponents}
		            onChange={ (e) =>{ this.setState({ fixedGroupComponents: e.target.value });}}
		          />
		          <TextField
		            margin="dense"
		            id="addFixedGroupCost"
		            label="Quota di partecipazione atleta extra (€)"
		            type="number"
		            fullWidth
		            variant="standard"
		            value={this.state.addFixedGroupCost}
		            onChange={ (e) =>{ this.setState({ addFixedGroupCost: e.target.value });}}
		          />
		        </div>
			  )}
			  { !this.state.fixedGroup && (
			  	<div>
		          <TextField
		            margin="dense"
		            id="groupCost"
		            label="Quota di partecipazione gruppo (€)"
		            type="number"
		            fullWidth
		            variant="standard"
		            value={this.state.groupCost}
		            onChange={ (e) =>{ this.setState({ groupCost: e.target.value });}}
		          />
		          <TextField
		            margin="dense"
		            id="secondGroupCost"
		            label="Quota di partecipazione secondo gruppo (€)"
		            type="number"
		            fullWidth
		            variant="standard"
		            value={this.state.secondGroupCost}
		            onChange={ (e) =>{ this.setState({ secondGroupCost: e.target.value });}}
		          />
		          <TextField
		            margin="dense"
		            id="thirdGroupCost"
		            label="Quota di partecipazione terzo gruppo (€)"
		            type="number"
		            fullWidth
		            variant="standard"
		            value={this.state.thirdGroupCost}
		            onChange={ (e) =>{ this.setState({ thirdGroupCost: e.target.value });}}
		          />
		        </div>
			  )}

	          <FormControlLabel 
	          	control={<Checkbox
				  checked={this.state.moreDays}
				  onChange={(event) =>{ 
		          	this.setState({ 
		          		dateEnd:'', 
		          		moreDays: event.target.checked, 
		          		labelDate: event.target.checked==false? 'Data di svolgimento':'Data inizio' 
		          	});
		          }}
				  inputProps={{ 'aria-label': 'controlled' }}
			  		/>
			  	} 
			  	label="Gara su più giorni" />
          	  <LocalizationProvider dateAdapter={AdapterDayjs}>
             	<DemoContainer components={['DatePicker']}>
	          		<DatePicker
		          	  id="date"
			          label={this.state.labelDate}
			          inputFormat="DD/MM/YYYY"
			          format="DD/MM/YYYY"
			          value={dayjs(this.state.date, "DD/MM/YYYY")}
			          onChange={(newValue) =>{ 
			          	this.setState({ date: newValue.format("DD/MM/YYYY") });
			          }}
		        	/>
		        </DemoContainer>
			  </LocalizationProvider>
			  { this.state.moreDays && ( 
			  	<LocalizationProvider dateAdapter={AdapterDayjs}>
	             	<DemoContainer components={['DatePicker']}>
		          		<DatePicker
			          	  id="dateEnd"
				          label="Data fine"
				          inputFormat="DD/MM/YYYY"
				          format="DD/MM/YYYY"
				          value={dayjs(this.state.dateEnd, "DD/MM/YYYY")}
				          onChange={(newValue) =>{ 
				          	this.setState({ dateEnd: newValue.format("DD/MM/YYYY") });
				          }}
			        	/>
			        </DemoContainer>
				</LocalizationProvider>
				)}
          	  <LocalizationProvider dateAdapter={AdapterDayjs}>
             	<DemoContainer components={['DatePicker']}>
	          		<DatePicker
		          	  id="registrationEnd"
			          label="Chiusura registrazioni"
			          inputFormat="DD/MM/YYYY"
			          format="DD/MM/YYYY"
			          value={dayjs(this.state.registrationEnd, "DD/MM/YYYY")}
			          onChange={(newValue) =>{ 
			          	this.setState({ registrationEnd: newValue.format("DD/MM/YYYY") });
			          }}
		        	/>
		        </DemoContainer>
			  </LocalizationProvider>
	          <InputFileUpload messageButton="Carica locandina" getFileFromUpload={this.getFileFromUpload} clear={this.clearPoster} type=".png"/>
	          <InputFileUpload messageButton="Carica regolamento" getFileFromUpload={this.getRulesFromUpload} clear={this.clearRules} type=".pdf"/>
	        </DialogContent>
	        <DialogActions>
	          <Button onClick={this.props.handleClose} disabled={this.state.showSpinner}>Chiudi</Button>
	          <Button onClick={this.addCompetition} disabled={this.state.showSpinner || !this.state.title || !this.state.region || !this.state.city || !this.state.poster || !this.state.rules}>{this.state.labelDialog}</Button>
	          {
	                this.state.showSpinner &&
			    	<Box sx={{ marginLeft:'5px', display: 'flex' }}>
			      		<CircularProgress />
			    	</Box>
	          }
	        </DialogActions>
	      </Dialog>
	  );
  }
}