import * as React from 'react';
import { DataGrid, itIT } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport} from '@mui/x-data-grid'; 
import Typography from '@mui/material/Typography';
import CustomGridLoadingOverlay from './custom-grid-loading-overlay-component';
import AddJudgeDialog from './add-judge-dialog-component';
import ConfirmDialog from './confirm-dialog-component'
import JudgeService from "../services/judge-service";

function ActionsButton(props) {
  const { deleteAction, editAction } = props;
  
  return (
  	<Grid container direction="row" alignItems="center">
	    <IconButton onClick={deleteAction}><DeleteIcon /></IconButton>
	</Grid>
  );
}

 function CustomToolbar(props) {
  const { addAction } = props;
  return (
    <GridToolbarContainer>
      <Grid container justifyContent="flex-start">
	      <GridToolbarColumnsButton />
	      <GridToolbarFilterButton />
      	  <GridToolbarExport 
	      	csvOptions={{
			    fileName: 'judges',
			    delimiter: ';',
		  	}}
		  />
	  </Grid>
      <Grid container justifyContent="flex-end">
	      <Button variant="contained" endIcon={<AddIcon />} onClick={addAction}>
	        Aggiungi
	      </Button>
	  </Grid>
    </GridToolbarContainer>
  );
}

export default class JudgesGrid extends React.Component {

  	constructor(props) {
	    super(props);
	    this.state = {
	      open: false,
	      openConfirm: false,
	      openEdit: false,
	      judgeToDelete: ''
	    };
  	}
  
  handleClickOpenConfirm = () => {
    this.setState({openConfirm: true})
  };

  handleCloseConfirm = () => {
    this.setState({openConfirm: false})
  };
  
  handleConfirmAction = () => {
    this.setState({openConfirm: false})
  };

  handleConfirmAction = async e => {
    e.preventDefault();
    JudgeService.deleteJudge(this.state.judgeToDelete).then(
      response => {
        this.handleCloseConfirm();
		this.props.refreshJudges();
      },
      error => {
        this.handleCloseConfirm();
		this.props.refreshJudges();
      }
    );
  } 
  
  handleClickOpen = () => {
    this.setState({open: true})
  };

  handleClose = () => {
    this.setState({open: false})
  };

  handleCloseEdit = () => {
    this.setState({openEdit: false})
  };
  
  columns = [
  	{
	    field: 'judgeId',
	    headerName: 'ID',
	    width: 70 
	  },
	  {
	    field: 'name',
	    headerName: 'Nome',
	    flex: 1,
	    editable: false,
	  },
	  {
	    field: 'surname',
	    headerName: 'Cognome',
	    editable: false,
	    flex: 1,
	  },
	  {
	    field: 'city',
	    headerName: 'Città',
	    editable: false,
	    flex: 1,
	  },
	  {
	    field: 'actions',
	    headerName: 'Azioni',
	    width: 100,
	    editable: false,
	    renderCell: (params) => {
	      
	      	const deleteAction = (e) => {
		        e.stopPropagation();
		
		        this.setState({
		        	openConfirm :true,
		        	judgeToDelete: params.row
		        })
	      	};
	
	      	return <ActionsButton deleteAction={deleteAction}/>;
	    }
	  },
	];
  
  render(){
	  return (
	    <div style={{ height: 450, width: '100%' }}>
	      <Typography variant="h4" gutterBottom component="div" >
	      	 Lista Giudici
	      </Typography>
	      <DataGrid
	        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
	        rows={this.props.judges}
	        columns={this.columns}
	        pageSize={5}
	        rowsPerPageOptions={[5]}
	        disableSelectionOnClick
	        loading={this.props.judges.length===0}
	        sx={{ marginBottom:'15px'}}
			components={{
	          Toolbar: CustomToolbar,
	          LoadingOverlay: CustomGridLoadingOverlay,
	        }}
	        componentsProps={{
	          toolbar: {addAction:this.handleClickOpen}
	        }}
	      />
	      <AddJudgeDialog
	      	open={this.state.open}
	        handleClose={this.handleClose}
	        refreshJudges={this.props.refreshJudges}
	      />
	      <ConfirmDialog
	      	open={this.state.openConfirm}
	        handleClose={this.handleCloseConfirm}
	        confirmAction={this.handleConfirmAction}
	        title={"Conferma cancellazione"}
	        message={"Sei sicuro di procedere con la cancellazione?"}
	      />
	    </div>
	  );
  }
}