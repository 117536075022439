import axios from "axios";

const API_URL = "https://sporteventmanagement.it/api/auth/";

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "signin", {
        username,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password, name, piva, address, cap, city, region, coach, phone, code) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password,
      name,
      piva,
      address,
      cap,
      city,
      region,
      coach,
      phone,
      code
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
}

export default new AuthService();
