import React from "react";
import AdminsGrid from "../components/competition-admins-grid-component";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import UserService from "../services/user-service";
import AuthService from "../services/auth-service";
import { Navigate } from "react-router-dom";

class Administrators extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        	redirect: null,
            showSpinner: false,
            admins: []
        }
    }
    
    async componentDidMount() {
    	const currentUser = AuthService.getCurrentUser();
	    if ((currentUser && !currentUser.roles.includes("ROLE_SUPERADMIN")) || !currentUser) this.setState({ redirect: "/home" });
	    UserService.getAllAdmins().then((adminsResponse) => {
	    	var admins = []
	    	adminsResponse.data.forEach(function(element, index) { 
	    		var admin =  { id: index+1, adminId: element.id, email: element.email, enabled: element.enabled };
				admins.splice(index, 0, admin);
			})
	    	this.setState({
		  		showSpinner: false,
				admins: admins
		  	})
	    }).catch((errorResponse) => {
	    	console.log(errorResponse)
	    });
	}
	
	refreshAdmins = async event => {
		this.setState({
		  	showSpinner: true,
		})
	    UserService.getAllAdmins().then((adminsResponse) => {
	    	var admins = []
	    	adminsResponse.data.forEach(function(element, index) { 
	    		var admin =  { id: index+1, adminId: element.id, email: element.email, enabled: element.enabled };
				admins.splice(index, 0, admin);
			})
	    	this.setState({
		  		showSpinner: false,
				admins: admins
		  	})
	    }).catch((errorResponse) => {
	    	console.log(errorResponse)
	    });
	}
	
	render(){
		if (this.state.redirect) {
	      return <Navigate to={this.state.redirect} />
	    }
	  	return (
	  	<div>
            {
                this.state.showSpinner &&
			    <div className="flex items-center justify-center mt-10 text-center px-6 py-6">
			    	<Box sx={{ display: 'flex' }}>
			      		<CircularProgress />
			    	</Box>
		        </div>
				
            }
            {
                !this.state.showSpinner &&
			    <div className="flex items-center justify-center text-center px-6 py-6">
					<AdminsGrid admins={this.state.admins} refreshAdmins={this.refreshAdmins}/>
		        </div>
            }
        </div>
	  );
	}
}

export default Administrators;