import * as React from 'react';
import { DataGrid, itIT } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport} from '@mui/x-data-grid'; 
import CustomGridLoadingOverlay from './custom-grid-loading-overlay-component';
import RegistrationDialog from './registration-dialog-component';
import ConfirmDialog from './confirm-dialog-component';
import CompetitionService from "../services/competition-service";
import { CustomFooterTotalComponent } from "./custom-footer-component";
import RegistrationService from "../services/registration-service";


function ActionsButton(props) {
  const { deleteAction, editAction } = props;
  
  return (
  	<Grid container direction="row" alignItems="center">
		<IconButton onClick={editAction}><EditIcon /></IconButton>
	    <IconButton onClick={deleteAction}><DeleteIcon /></IconButton>
	</Grid>
  );
}

 function CustomToolbar(props) {
  const { addAction } = props;
  return (
    <GridToolbarContainer>
      <Grid container justifyContent="flex-end">
          <Grid container justifyContent="flex-start">
		      <GridToolbarColumnsButton />
		      <GridToolbarFilterButton />
		      <GridToolbarExport 
		      	csvOptions={{
				    fileName: 'registrations',
				    delimiter: ';',
			  	}}
			  />
		  </Grid>
	      <Button variant="contained" endIcon={<AddIcon />} onClick={addAction}>
	        Nuova iscrizione
	      </Button>
	  </Grid>
    </GridToolbarContainer>
  );
}

export default class RegistrationGrid extends React.Component {

  	constructor(props) {
	    super(props);
	    this.state = {
	      open: false,
	      openConfirm: false,
	      rowToDelete: '',
	      rowToEdit:'',
	      total: 0
	    };
  	}

  	getTotalCost = () => {
  		var total = 0
	    var unitCost = this.props.competition.unitCost
	    var secondUnitCost = this.props.competition.secondUnitCost
	    var thirdUnitCost = this.props.competition.thirdUnitCost
	    var coupleCost = this.props.competition.coupleCost
	    var secondCoupleCost = this.props.competition.secondCoupleCost
	    var thirdCoupleCost = this.props.competition.thirdCoupleCost
	    var groupCost = this.props.competition.groupCost
	    var secondGroupCost = this.props.competition.secondGroupCost
	    var thirdGroupCost = this.props.competition.thirdGroupCost
	    var fixedGroupCost = this.props.competition.fixedGroupCost
	    var fixedGroupComponents = this.props.competition.fixedGroupComponents
	    var addFixedGroupCost = this.props.competition.addFixedGroupCost
	    var unitMap = new Map()
	    var coupleMap = new Map()
	    var groupMap = new Map()
	    this.props.elements.forEach(function(registration){
	    	if(registration.gymnasts.length == 1){
	    		if(!unitMap.has(registration.gymnasts[0].cf)){
	    			unitMap.set(registration.gymnasts[0].cf,1)
	    			total += registration.gymnasts.length * unitCost
	    		}else{
	    			var number = unitMap.get(registration.gymnasts[0].cf)
	    			if(number>=2){
	    				total += registration.gymnasts.length * thirdUnitCost
	    			}else{
	    				total += registration.gymnasts.length * secondUnitCost
	    			}
	    			unitMap.set(registration.gymnasts[0].cf, number+1)
	    		}
	    	}else if(registration.gymnasts.length == 2){
	    		registration.gymnasts.forEach(function(gymnast){
		    		if(!coupleMap.has(gymnast.cf)){
		    			coupleMap.set(gymnast.cf, 1)
		    			total +=  coupleCost * 1
		    		}else{
		    			var number = coupleMap.get(gymnast.cf)
		    			if(number>=2){
		    				total += thirdCoupleCost * 1
		    			}else{
		    				total += secondCoupleCost * 1
		    			}
		    			coupleMap.set(gymnast.cf, number+1)
		    		}
	    		})
	    	}else if(registration.gymnasts.length > 2){
	    		if(fixedGroupCost!=null && fixedGroupCost!=''){
					total += fixedGroupCost * 1
    				if(registration.gymnasts.length > fixedGroupComponents){
    					total += (addFixedGroupCost * (registration.gymnasts.length-fixedGroupComponents))
    				}
	    		}else{
					registration.gymnasts.forEach(function(gymnast){
			    		if(!groupMap.has(gymnast.cf)){
			    			groupMap.set(gymnast.cf, 1)
			    			total +=  groupCost * 1
			    		}else{
			    			var number = groupMap.get(gymnast.cf)
			    			if(number>=2){
			    				total += thirdGroupCost * 1
			    			}else{
			    				total += secondGroupCost * 1
			    			}
			    			groupMap.set(gymnast.cf, number+1)
			    		}
		    		})	
	    		}		    	
	    	}
	    })
	    return total
  	}
  
	async componentDidMount() {
	    var total = this.getTotalCost()
	    this.setState({
	    	total : total
	    })
	}

	async componentDidUpdate() {
	    var total = this.getTotalCost()
	    if(this.state.total != total){
		    this.setState({
		    	total : total
		    })
		}
	}
  
  handleClickOpenConfirm = () => {
    this.setState({openConfirm: true})
  };

  handleCloseConfirm = () => {
    this.setState({openConfirm: false})
  };
  
  handleConfirmAction = () => {
    this.setState({openConfirm: false})
  };
  
  handleConfirmAction = async e => {
    e.preventDefault();
    var registrationToDelete={
    	id: this.state.rowToDelete.registrationId
    }
    RegistrationService.delete(registrationToDelete).then(
      response => {
        this.handleCloseConfirm();
		this.props.refreshCompetition();
      },
      error => {
        this.handleCloseConfirm();
		this.props.refreshCompetition();
      }
    );
  } 
  
  handleClickOpen = () => {
    this.setState({open: true})
  };

  handleClose = () => {
    this.setState({
    	open: false,
    	rowToEdit: null
    })
  };
  
  columns = [
  		{
	    field: 'number',
	    headerName: 'Numero Iscrizione',
	    flex: 1,
	    editable: false,
	  },
      {
	    field: 'association',
	    headerName: 'Associazione',
	    flex: 1,
	    editable: false,
	    valueGetter: (params) => {
	    	return params.value.name
	    },
	    renderCell: (params) => {
	    	return params.value.name
	    }
	  },
  	  {
	    field: 'gymnasts',
	    headerName: 'Atleta/i',
	    flex: 1,
	    editable: false,
	    valueGetter: (params) => {
	    	return params.value.map(function(gymnast){ return gymnast.name + ' ' + gymnast.surname}).join(",")
	    }
	  },
	  {
	    field: 'discipline',
	    headerName: 'Disciplina',
	    flex: 1,
	    editable: false,
	    valueGetter: (params) => {
	    	return params.value.name
	    },
	    renderCell: (params) => {
	    	return params.value.name
	    }
	  },
  	  {
	    field: 'choreographyName',
	    headerName: 'Nome Coreografia',
	    flex: 1,
	    editable: false,
	  },
	  {
	    field: 'category',
	    headerName: 'Categoria',
	    flex: 1,
	    editable: false,
	    valueGetter: (params) => {
	    	return params.value.name
	    },
	    renderCell: (params) => {
	    	return params.value.name
	    }
	  },
	  {
	    field: 'sportClass',
	    headerName: 'Classe/Attrezzo',
	    editable: false,
	    flex: 1,
	    valueGetter: (params) => {
	    	return params.value.name
	    },
	    renderCell: (params) => {
	    	return params.value.name
	    }
	  },
	  {
	    field: 'unit',
	    headerName: 'Unità Comp.',
	    editable: false,
	    flex: 1,
	    valueGetter: (params) => {
	    	return params.value.name
	    },
	    renderCell: (params) => {
	    	return params.value.name
	    }
	  },
	  {
	    field: 'actions',
	    headerName: 'Azioni',
	    width: 100,
	    editable: false,
	    renderCell: (params) => {
	      	const editAction = (e) => {
	        	e.stopPropagation();
	        	this.setState({
	        		rowToEdit: params.row,
		        	open: true
		        })
	      	};
	      
	      	const deleteAction = (e) => {
		        e.stopPropagation();
		        this.setState({
		        	openConfirm :true,
		        	rowToDelete: params.row
		        })
	      	};
			
	      	return <ActionsButton editAction={editAction} deleteAction={deleteAction}/>;
	    }
	  },
	];
  
  render(){
	  return (
	    <div style={{ height: 450, width: '100%' }}>
	      <DataGrid
	      	localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
	        rows={this.props.elements}
	        columns={
	        	this.columns
	        }
	        columnVisibilityModel={{
			    association: this.props.isAdmin,
			}}
	        pageSize={5}
	        rowsPerPageOptions={[5]}
	        disableSelectionOnClick
	        loading={this.props.elements.length===0}
	        sx={{ marginBottom:'15px'}}
			components={{
	          Toolbar: CustomToolbar,
	          LoadingOverlay: CustomGridLoadingOverlay,
          	  Footer: CustomFooterTotalComponent
	        }}
	        componentsProps={{
	          toolbar: {addAction:this.handleClickOpen},
	          footer: { total : this.state.total}
	        }}
	      />
	      <RegistrationDialog
	      	open={this.state.open}
	        handleClose={this.handleClose}
	        registrationToEdit={this.state.rowToEdit}
	        refreshRegistrations={this.props.refreshCompetition}
	        gymnasts={this.props.gymnasts}
	        matches={this.props.matches}
	        competitionId={this.props.competitionId}
	      />
	      <ConfirmDialog
	      	open={this.state.openConfirm}
	        handleClose={this.handleCloseConfirm}
	        confirmAction={this.handleConfirmAction}
	        title={"Conferma cancellazione"}
	        message={"Sei sicuro di procedere con la cancellazione?"}
	      />
	    </div>
	  );
  }
}
