import React, { Component } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.min.css";
import "./App.css";

import AuthService from "./services/auth-service";
import Login from "./components/login-component";
import Register from "./components/register-component";
import Home from "./components/home-component";
import Profile from "./components/profile-component";
import BoardUser from "./components/board-user-component";
import BoardAdmin from "./components/board-admin-component";
import BoardSuperAdmin from "./components/board-superadmin-component";
import Gymnasts from "./pages/gymnasts-page";
import Competitions from "./pages/competitions-page";
import Competition from "./pages/competition-page";
import Registration from "./pages/registration-page";
import Configurations from "./pages/configurations-page";
import Associations from "./pages/associations-page";
import Judges from "./pages/judges-page";
import Help from "./pages/help-page";
import Administrators from "./pages/administrators-page";
import Logo from './images/sem_logo.png';

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showAdminBoard: false,
      showSuperAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();
    if (user) {
      this.setState({
        currentUser: user,
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
        showSuperAdminBoard: user.roles.includes("ROLE_SUPERADMIN"),
      });
    }
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showAdminBoard: false,
      showSuperAdminBoard: false,
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser, showAdminBoard, showSuperAdminBoard } = this.state;

    return (
      <div>
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <Link to={"/"} className="navbar-brand">
            <img src={Logo} style={{ width: 150, height: 65, "margin-left": 15 }} />
          </Link>
          <div className="navbar-nav me-auto">
            <li className="nav-item">
              <Link to={"/home"} className="nav-link">
                Home
              </Link>
            </li>

            {showSuperAdminBoard && (
              <li className="nav-item">
                <Link to={"/associations"} className="nav-link">
                  Associazioni
                </Link>
              </li>
            )}

            {showSuperAdminBoard && (
              <li className="nav-item">
                <Link to={"/judges"} className="nav-link">
                  Giudici
                </Link>
              </li>
            )}

            {showSuperAdminBoard && (
              <li className="nav-item">
                <Link to={"/configurations"} className="nav-link">
                  Configurazioni
                </Link>
              </li>
            )}

            {showSuperAdminBoard && (
              <li className="nav-item">
                <Link to={"/administrators"} className="nav-link">
                  Amministratori
                </Link>
              </li>
            )}

            {currentUser && !showAdminBoard && (
              <li className="nav-item">
                <Link to={"/gymnasts"} className="nav-link">
                  Atleti
                </Link>
              </li>
            )}

            <li className="nav-item">
              <Link to={"/help"} className="nav-link">
                Contatti
              </Link>
            </li>
          </div>

          {currentUser ? (
            <div className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link to={"/profile"} className="nav-link">
                  {currentUser.username}
                </Link>
              </li>
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={this.logOut}>
                  LogOut
                </a>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Login
                </Link>
              </li>

              <li className="nav-item">
                <Link to={"/register"} className="nav-link">
                  Sign Up
                </Link>
              </li>
            </div>
          )}
        </nav>

        <div className="container mt-3">
          <Routes>
            <Route path="/" element={<Competitions />} />
            <Route path="/home" element={<Competitions />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/gymnasts" element={<Gymnasts />} />
            <Route path="/associations" element={<Associations />} />
            <Route path="/competition/:competitionId" element={<Competition />} />
            <Route path="/competition/:competitionId/registration" element={<Registration />} />
            <Route path="/configurations" element={<Configurations />} />
            <Route path="/judges" element={<Judges />} />
            <Route path="/administrators" element={<Administrators />} />
            <Route path="/help" element={<Help />} />
          </Routes>
        </div>
      </div>
    );
  }
}

export default App;