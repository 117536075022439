import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import UserService from "../services/user-service";

export default class EditAssociationDialog extends React.Component {

  	constructor(props) {
	    super(props);
	    this.state = {
	      name: this.props.association.name,
	      username: this.props.association.username,
	      email: this.props.association.email,
	      piva: this.props.association.piva,
	      code: this.props.association.code,
	      address: this.props.association.address,
	      cap: this.props.association.cap,
	      city: this.props.association.city,
	      region: this.props.association.region,
	      phone: this.props.association.phone,
	      coach: this.props.association.coach,
	      showSpinner: false
	    };
  	}

	componentDidUpdate(prevProps, prevState) {
		if( this.props.association.name !== prevProps.association.name 
			|| this.props.association.username !== prevProps.association.username
			|| this.props.association.email !== prevProps.association.email
			|| this.props.association.piva !== prevProps.association.piva
			|| this.props.association.code !== prevProps.association.code
			|| this.props.association.address !== prevProps.association.address
			|| this.props.association.cap !== prevProps.association.cap
			|| this.props.association.city !== prevProps.association.city
			|| this.props.association.region !== prevProps.association.region
			|| this.props.association.phone !== prevProps.association.phone
			|| this.props.association.coach !== prevProps.association.coach){
			this.setState({
		      	name: this.props.association.name,
		      	username: this.props.association.username,
		      	email: this.props.association.email,
		      	piva: this.props.association.piva,
			    code: this.props.association.code,
			    address: this.props.association.address,
			    cap: this.props.association.cap,
			    city: this.props.association.city,
			    region: this.props.association.region,
			    phone: this.props.association.phone,
			    coach: this.props.association.coach,
		      	showSpinner: false
			})
		}
	}

  	editAssociation = async e => {
	    e.preventDefault();
	    this.setState({showSpinner: true})
	    var associationToEdit={
	    	username: this.state.username,
	      	name: this.state.name,
	      	email: this.state.email,
	      	piva: this.state.piva,
		    code: this.state.code,
		    address: this.state.address,
		    cap: this.state.cap,
		    city: this.state.city,
		    region: this.state.region,
		    phone: this.state.phone,
		    coach: this.state.coach,
	    }

		UserService.editAssociation(associationToEdit).then(
	        response => {
	            this.setState({showSpinner: false})
	    		this.props.handleClose();
				this.props.refreshAssociations();
	        },
	        error => {
	            this.setState({showSpinner: false})
	    		this.props.handleClose();
				this.props.refreshAssociations();
	        }
	    );
  	}  
  
  	render(){
	  return (
	      <Dialog
	        open={this.props.open}
	        aria-labelledby="alert-dialog-title"
	        aria-describedby="alert-dialog-description"
	      >
	        <DialogTitle id="alert-dialog-title">
	          <b>Modifica Associazione</b>
	        </DialogTitle>
	        <DialogContent>
	          <TextField
	          	disabled
	            margin="dense"
	            id="email"
	            label="Email"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.email}
	            onChange={ (e) =>{ this.setState({ email: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="name"
	            label="Nome Associazione"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.name}
	            onChange={ (e) =>{ this.setState({ name: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="username"
	            label="Username"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.username}
	            onChange={ (e) =>{ this.setState({ username: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="code"
	            label="Codice affiliazione"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.code}
	            onChange={ (e) =>{ this.setState({ code: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="piva"
	            label="P. IVA/Cod. Fiscale Associazione"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.piva}
	            onChange={ (e) =>{ this.setState({ piva: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="region"
	            label="Regione"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.region}
	            onChange={ (e) =>{ this.setState({ region: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="city"
	            label="Città"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.city}
	            onChange={ (e) =>{ this.setState({ city: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="address"
	            label="Indirizzo"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.address}
	            onChange={ (e) =>{ this.setState({ address: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="cap"
	            label="CAP"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.cap}
	            onChange={ (e) =>{ this.setState({ cap: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="phone"
	            label="Telefono"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.phone}
	            onChange={ (e) =>{ this.setState({ phone: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="coach"
	            label="Coach"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.coach}
	            onChange={ (e) =>{ this.setState({ coach: e.target.value });}}
	          />
	        </DialogContent>
	        <DialogActions>
	          <Button onClick={this.props.handleClose} disabled={this.state.showSpinner}>Chiudi</Button>
	          <Button onClick={this.editAssociation} disabled={this.state.showSpinner}>Modifica</Button>
	          {
	                this.state.showSpinner &&
			    	<Box sx={{ marginLeft:'5px', display: 'flex' }}>
			      		<CircularProgress />
			    	</Box>
	          }
	        </DialogActions>
	      </Dialog>
	  );
  }
}