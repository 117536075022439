import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import RegistrationService from "../services/registration-service";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import UserService from "../services/user-service";
import InputFileUpload from './upload-button-component';

export default class AddPaymentDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      association: null,
      paymentData: '',
      associations: [],
      isAdmin: false,
      showSpinner: false
    };
  }

	async componentDidMount() {
	    if (this.props.association && (this.props.association.roles.includes("ROLE_SUPERADMIN") || this.props.association.roles.includes("ROLE_ADMIN"))) {
			UserService.getAll().then(
		        response => {
			      	this.setState({
			        	associations: response.data,
			        	isAdmin: true
			      	});
		        },
		        error => {
			      	console.log(error)
		        }
		    );
	    }else{
	    	this.setState({
	    		association: this.props.association
	    	})
	    }
	}

  clearPayment = () => {
  	this.setState({
  		paymentData: ''
  	})
  }

  getPaymentFromUpload = (event) => {
  	var uploadedFileBase64 = event.target.files[0]
  	if(uploadedFileBase64){
	  	var filereader = new FileReader();
		filereader.readAsDataURL(uploadedFileBase64);
		const scope = this
		filereader.onload = function (evt) {
		    var base64 = evt.target.result;
	    	scope.setState({paymentData: base64})
		}
	}
  };
  
  addPayment = async e => {
    e.preventDefault();
    const today = new Date();
	const yyyy = today.getFullYear();
	let mm = today.getMonth() + 1; // Months start at 0!
	let dd = today.getDate();

	if (dd < 10) dd = '0' + dd;
	if (mm < 10) mm = '0' + mm;

	const formattedToday = dd + '/' + mm + '/' + yyyy;
    var paymentToCreate={
      	paymentData: this.state.paymentData,
      	date: formattedToday,
        association: this.state.association
    }
    this.setState({showSpinner: true})

	RegistrationService.addPayment(paymentToCreate, this.props.competitionId).then(
        response => {
            this.setState({showSpinner: false})
    		this.props.handleClose();
			this.props.refreshPayments();
        },
        error => {
          this.setState({showSpinner: false})
    		this.props.handleClose();
			this.props.refreshPayments();
        }
    );
  }  
  
  render(){
	  return (
	      <Dialog
	        open={this.props.open}
	        aria-labelledby="alert-dialog-title"
	        aria-describedby="alert-dialog-description"
	      >
	        <DialogTitle id="alert-dialog-title">
	          <b>Aggiungi Pagamento</b>
	        </DialogTitle>
	        <DialogContent>
		        {this.state.isAdmin && ( <FormControl fullWidth>
				  <InputLabel id="associationInputLabel">Associazione</InputLabel>
				  <Select
				    labelId="associationInputLabel"
				    id="associationInput"
				    label="Associazione"
				    onChange={ (e) =>{ this.setState({ association: this.state.associations.filter(function(assoc){return assoc.username===e.target.value})[0] });}}
				  >
				    {this.state.associations.map((data) => (
					  <MenuItem key={data.username} value={data.username}>{data.name}</MenuItem>
					))}
				  </Select>
				</FormControl>
				)}
	          	<InputFileUpload messageButton="Carica pagamento" getFileFromUpload={this.getPaymentFromUpload} clear={this.clearPayment} type=".pdf"/>
	        </DialogContent>
	        <DialogActions>
	          <Button onClick={this.props.handleClose} disabled={this.state.showSpinner}>Chiudi</Button>
	          <Button onClick={this.addPayment} disabled={this.state.showSpinner}>Aggiungi</Button>
	          {
	                this.state.showSpinner &&
			    	<Box sx={{ marginLeft:'5px', display: 'flex' }}>
			      		<CircularProgress />
			    	</Box>
	          }
	        </DialogActions>
	      </Dialog>
	  );
  }
}