import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import AuthService from "../services/auth-service";

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      userReady: false,
      currentUser: { username: "" }
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) this.setState({ redirect: "/home" });
    this.setState({ currentUser: currentUser, userReady: true })
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }

    const { currentUser } = this.state;

    return (
      <div className="container">
        {(this.state.userReady) ?
        <div>
        <header className="jumbotron">
          <h3>
            <strong>{currentUser.username}</strong> Il tuo profilo
          </h3>
        </header>
        <p>
          <strong>Email:</strong>{" "}
          {currentUser.email}
        </p>
        <p>
          <strong>Nome Associazione:</strong>{" "}
          {currentUser.name}
        </p>
        <p>
          <strong>Partita IVA:</strong>{" "}
          {currentUser.piva}
        </p>
        <p>
          <strong>Città:</strong>{" "}
          {currentUser.city}
        </p>
        <p>
          <strong>Regione:</strong>{" "}
          {currentUser.region}
        </p>
        <p>
          <strong>Allenatore:</strong>{" "}
          {currentUser.coach}
        </p>
      </div>: null}
      </div>
    );
  }
}