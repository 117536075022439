import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import CompetitionService from "../services/competition-service";
import AuthService from "../services/auth-service";
import TransferList from './transfer-list-component';
import SportClassService from "../services/class-service";
import CategoryService from "../services/category-service";
import DisciplineService from "../services/discipline-service";
import UnitService from "../services/unit-service";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';


/*
const isSameElement = (a, b) => a.id === b.id && a.name === b.name;

const onlyInLeft = (left, right, compareFunction) => 
  left.filter(leftValue =>
    !right.some(rightValue => 
      compareFunction(leftValue, rightValue)));
*/

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default class AddRowElementDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      disciplines: [],
      categories: [],
      classes: [],
      units: [],
      selectedDisciplines: this.props.rowToEdit ? this.props.rowToEdit.discipline[0]:'',
      selectedCategories: this.props.rowToEdit ? this.props.rowToEdit.category:[],
      selectedClasses: this.props.rowToEdit ? this.props.rowToEdit.sportClass:[],
      selectedUnits: this.props.rowToEdit ? this.props.rowToEdit.unit:[],
      isAdmin: false,
      currentUser:'',
      showSpinner: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.rowToEdit && !prevProps.rowToEdit){
      this.setState({
        selectedDisciplines:  this.props.rowToEdit.discipline[0],
        selectedCategories: this.props.rowToEdit.category,
        selectedClasses: this.props.rowToEdit.sportClass,
        selectedUnits: this.props.rowToEdit.unit,
        showSpinner: false
      })
    }
  }

  componentDidMount() {
      const currentUser = AuthService.getCurrentUser();
      if (currentUser && !currentUser.roles.includes("ROLE_SUPERADMIN")) this.setState({ redirect: "/home" });
      DisciplineService.getAll().then((disciplineResponse) => {
        this.setState({
          disciplines: disciplineResponse.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
          //notSelectedDisciplines: disciplineResponse.data,
          showSpinner: false
        })
      }).catch((errorResponse) => {
        this.setState({
        	disciplines: [],
        	showSpinner: false
      	})
      });
      CategoryService.getAll().then((categoryResponse) => {
        this.setState({
          categories: categoryResponse.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
         // notSelectedCategories: categoryResponse.data,
          showSpinner: false
        })
      }).catch((errorResponse) => {
          this.setState({
            categories: [],
            showSpinner: false
          })
      });
      SportClassService.getAll().then((sportClassesResponse) => {
        this.setState({
          classes: sportClassesResponse.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
          //notSelectedClasses: sportClassesResponse.data,
          showSpinner: false
        })
      }).catch((errorResponse) => {
          this.setState({
            classes: [],
            showSpinner: false
          })
      });
      UnitService.getAll().then((unitResponse) => {
        this.setState({
          units: unitResponse.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
          //notSelectedUnits: unitResponse.data,
          showSpinner: false
        })
      }).catch((errorResponse) => {
          this.setState({
            units: [],
            showSpinner: false
          })
      });
  }
  
  addElement = async e => {
    e.preventDefault();
    //this.setState({showSpinner: true})
    var elementToCreate={
    	id: this.props.rowToEdit ? this.props.rowToEdit.matchId : null,
    	competitionId: this.props.competitionId,
    	disciplines: new Array(this.state.selectedDisciplines),
    	categories: this.state.selectedCategories,
      sportClasses: this.state.selectedClasses,
      units: this.state.selectedUnits
    }

	CompetitionService.createMatch(elementToCreate).then(
        response => {
          this.setState({showSpinner: false})
    		  this.props.handleClose();
			    this.props.refreshElements();
        },
        error => {
          this.setState({showSpinner: false})
    		  this.props.handleClose();
			    this.props.refreshElements();
        }
    );
  }

/*  updateDiscipline = (disciplines) => {
    this.setState({selectedDisciplines: disciplines})
  };

  updateCategories = (categories) => {
    this.setState({selectedCategories: categories})
  };

  updateClasses = (classes) => {
    this.setState({selectedClasses: classes})
  };

  updateUnits = (units) => {
    this.setState({selectedUnits: units})
  };
*/
  render(){
	  return (
	      <Dialog
	        open={this.props.open}
	        aria-labelledby="alert-dialog-title"
	        aria-describedby="alert-dialog-description"
	      >
	        <DialogTitle id="alert-dialog-title">
	          <b>Aggiungi Gara</b>
	        </DialogTitle>
	        <DialogContent sx={{ minWidth: "400px" }}>
            <FormControl sx={{ m: 1, width: "95%" }}>
              <InputLabel id="input-discipline-label">Disciplina</InputLabel>
              <Select
                id="input-discipline-select"
                onChange={(event) => {
                  this.setState({
                    selectedDisciplines: event.target.value
                  })
                }}
                defaultValue=''
                value={this.state.disciplines.find((disc) => disc.name === this.state.selectedDisciplines.name)}
                MenuProps={MenuProps}
              >
                {this.state.disciplines.map((discipline) => (
                  <MenuItem value={discipline}>
                    {discipline.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, width: "95%" }}>
              <InputLabel id="input-categories-label">Categorie</InputLabel>
              <Select
                id="input-categories-select"
                multiple
                onChange={(event) => {
                  this.setState({
                    selectedCategories: event.target.value
                  })
                }}
                value={this.state.categories.filter((el) => this.state.selectedCategories.find((el1)=>el1.id===el.id))}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    { 
                      selected.map((sel) => (
                        <Chip
                          key={sel.id}
                          label={sel.name}
                          variant="light"
                          color="primary"
                          size="small"
                        />
                      ))
                    }
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {this.state.categories.map((category) => (
                  <MenuItem key={category.id} value={category}>
                    <Checkbox  checked={this.state.selectedCategories.map(function(el){return el.id}).indexOf(category.id) > -1}/>
                    <ListItemText primary={category.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, width: "95%" }}>
              <InputLabel id="input-classes-label">Classi/Attrezzi</InputLabel>
              <Select
                id="input-classes-select"
                multiple
                onChange={(event) => {
                  this.setState({
                    selectedClasses: event.target.value
                  })
                }}
                value={this.state.classes.filter((el) => this.state.selectedClasses.find((el1)=>el1.id===el.id))}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    { 
                      selected.map((sel) => (
                        <Chip
                          key={sel.id}
                          label={sel.name}
                          variant="light"
                          color="primary"
                          size="small"
                        />
                      ))
                    }
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {this.state.classes.map((sportClass) => (
                  <MenuItem key={sportClass.id} value={sportClass}>
                    <Checkbox checked={this.state.selectedClasses.map(function(el){return el.id}).indexOf(sportClass.id) > -1} />
                    <ListItemText primary={sportClass.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, width: "95%" }}>
              <InputLabel id="input-units-label">Unità competitive</InputLabel>
              <Select
                id="input-units-select"
                multiple
                onChange={(event) => {
                  this.setState({
                    selectedUnits: event.target.value
                  })
                }}
                value={this.state.units.filter((el) => this.state.selectedUnits.find((el1)=>el1.id===el.id))}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    { 
                      selected.map((sel) => (
                        <Chip
                          key={sel.id}
                          label={sel.name}
                          variant="light"
                          color="primary"
                          size="small"
                        />
                      ))
                    }
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {this.state.units.map((unit) => (
                  <MenuItem key={unit.id} value={unit}>
                    <Checkbox checked={this.state.selectedUnits.map(function(el){return el.id}).indexOf(unit.id) > -1} />
                    <ListItemText primary={unit.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
	          {/*<TransferList title='Disciplina' elements={this.state.disciplines} updateSelected={this.updateDiscipline} notSelected={this.state.notSelectedDisciplines} selected={this.state.selectedDisciplines}/>
            <TransferList title='Categoria' elements={this.state.categories} updateSelected={this.updateCategories} notSelected={this.state.notSelectedCategories} selected={this.state.selectedCategories}/>
	          <TransferList title='Classe/Attrezzo' elements={this.state.classes} updateSelected={this.updateClasses} notSelected={this.state.notSelectedClasses} selected={this.state.selectedClasses}/>
	          <TransferList title='Unità competitiva' elements={this.state.units} updateSelected={this.updateUnits} notSelected={this.state.notSelectedUnits} selected={this.state.selectedUnits}/>*/}
	        </DialogContent>
	        <DialogActions>
	          <Button onClick={() =>{this.setState({selectedDisciplines:'',selectedCategories:[],selectedClasses:[],selectedUnits:[]}); this.props.handleClose()}} disabled={this.state.showSpinner}>Chiudi</Button>
	          <Button onClick={(e) =>{this.setState({selectedDisciplines:'',selectedCategories:[],selectedClasses:[],selectedUnits:[]}); this.addElement(e)}} disabled={this.state.showSpinner || !this.state.selectedDisciplines || this.state.selectedCategories.length == 0 || this.state.selectedClasses.length == 0 || this.state.selectedUnits.length == 0}>Aggiungi</Button>
	          {
	                this.state.showSpinner &&
			    	<Box sx={{ marginLeft:'5px', display: 'flex' }}>
			      		<CircularProgress />
			    	</Box>
	          }
	        </DialogActions>
	      </Dialog>
	  );
  }
}