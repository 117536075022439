import React from "react";
import AssociationsGrid from '../components/associations-grid-component';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import AuthService from "../services/auth-service";
import UserService from "../services/user-service";
import { Navigate } from "react-router-dom";


class Associations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        	redirect: null,
            showSpinner: false,
            associations: []
        }
    }
    
    async componentDidMount() {
    	const currentUser = AuthService.getCurrentUser();
	    if ((currentUser && !currentUser.roles.includes("ROLE_SUPERADMIN")) || !currentUser) this.setState({ redirect: "/home" });
	    UserService.getAll().then((response) => {
	    	var associations = []
	    	response.data.forEach(function(element, index) { 
	    		var association =  { id: index+1, name: element.name, username: element.username, email: element.email, piva: element.piva, code: element.code, address: element.address, cap: element.cap, city: element.city, region: element.region, phone: element.phone, coach: element.coach, enabled: element.enabled };
				associations.splice(index, 0, association);
			})
	    	this.setState({
		  		showSpinner: false,
				associations: associations
		  	})
	    }).catch((errorResponse) => {
	    	console.log(errorResponse)
	    });
	}
	
	refreshAssociations = async event => {
		this.setState({
		  	showSpinner: true,
		})
	    UserService.getAll().then((response) => {
	    	var associations = []
	    	response.data.forEach(function(element, index) { 
	    		var association =  { id: index+1, name: element.name, username: element.username, email: element.email, piva: element.piva, code: element.code, address: element.address, cap: element.cap, city: element.city, region: element.region, phone: element.phone, coach: element.coach, enabled: element.enabled };
				associations.splice(index, 0, association);
			})
	    	this.setState({
		  		showSpinner: false,
				associations: associations
		  	})
	    });
	}
	
	render(){
		if (this.state.redirect) {
	      return <Navigate to={this.state.redirect} />
	    }
	  	return (
	  	<div>
            {
                this.state.showSpinner &&
			    <div className="flex items-center justify-center mt-10 text-center px-6 py-6">
			    	<Box sx={{ display: 'flex' }}>
			      		<CircularProgress />
			    	</Box>
		        </div>
				
            }
            {
                !this.state.showSpinner &&
			    <div className="flex items-center justify-center text-center px-6 py-6">
					<AssociationsGrid associations={this.state.associations} refreshAssociations={this.refreshAssociations}/>
		        </div>
            }
        </div>
	  );
	}
}

export default Associations;