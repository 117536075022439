import * as React from 'react';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import AddElementDialog from './add-element-dialog-component.js'
import DisciplineService from "../services/discipline-service";
import SportClassService from "../services/class-service";
import CategoryService from "../services/category-service";
import ConfirmDialog from './confirm-dialog-component'
import UnitService from "../services/unit-service";

export default class ListElement extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
    	open: false,
    	openConfirm: false,
      	messageButton: props.messageButton,
      	elementToEdit: '',
      	elementToDelete: ''
    };
  }

  handleClickOpen = () => {
    this.setState({open: true})
  };

  handleClose = () => {
    this.setState({
    	open: false, 
    	elementToEdit: ''
    })
  };

  handleClickOpenConfirm = (element) => () => {
    this.setState({
    	openConfirm: true,
    	elementToDelete: element
    })
  };

  handleClickOpenEditConfirm = (element) => () => {
    this.setState({
    	open: true,
    	elementToEdit: element
    })
  };

  handleCloseConfirm = () => {
    this.setState({openConfirm: false})
  };
  
  handleConfirmAction = () => {
    this.setState({openConfirm: false})
  };

  handleConfirmAction = async e => {
    e.preventDefault();
    var elementService
	switch(this.props.type) {
	    case 'discipline':
	    	elementService = DisciplineService;
	    	break;
	    case 'category':
	    	elementService = CategoryService;
	    	break;
	    case 'sportClass':
	    	elementService = SportClassService;
	    	break;
	    case 'units':
	    	elementService = UnitService;
	    	break;
	    default:
	    	elementService = null
	}
	if(elementService){
		elementService.delete(this.state.elementToDelete).then(
	        response => {
	            this.setState({showSpinner: false})
        		this.handleCloseConfirm();
				this.props.refreshElements();
	        },
	        error => {
	          this.setState({showSpinner: false})
        		this.handleCloseConfirm();
				this.props.refreshElements();
	        }
	    );		
	}
  }

  render(){
    return (
      <div style={{ width: '95%'}}>
		<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'lightgrey' }}>
		  {this.props.elements.length == 0 && (
		  	<ListItem key="0">
		  		Nessun elemento
		  	</ListItem>
		  )}
		  {this.props.type != "category" && this.props.elements.map((element) => (
		    <ListItem
		      key={element.id}
		      disableGutters
		      sx={{paddingLeft:"10px"}}
		      secondaryAction={
		      	<div>
			      	<IconButton aria-label="comment" onClick={this.handleClickOpenEditConfirm(element)}>
			          <EditIcon />
			        </IconButton>
			        <IconButton aria-label="comment" onClick={this.handleClickOpenConfirm(element)}>
			          <DeleteIcon />
			        </IconButton>
			    </div>
		      }
		    >
		      <ListItemText primaryTypographyProps={{ fontSize: "13px" }} primary={`${element.name}`}/>
		    </ListItem>
		  ))}
		  {this.props.type == "category" && this.props.elements.map((element) => (
		    <ListItem
		      key={element.id}
		      disableGutters
		      sx={{paddingLeft:"10px"}}
		      secondaryAction={
		      	<div>
			      	<IconButton size='small' aria-label="comment" onClick={this.handleClickOpenEditConfirm(element)}>
			          <EditIcon />
			        </IconButton>
			        <IconButton aria-label="comment" onClick={this.handleClickOpenConfirm(element)}>
			          <DeleteIcon />
			        </IconButton>
			    </div>
		      }
		    >
		      <ListItemText primaryTypographyProps={{ fontSize: "13px" }} primary={`${element.name} (${element.min}/${element.max})`}/>
		    </ListItem>
		  ))}
		</List>
        <Button sx={{ marginTop: '20px' }} component="label" variant="contained" onClick={this.handleClickOpen}>
          {this.state.messageButton}
        </Button>
        <AddElementDialog 
			open={this.state.open}
	        handleClose={this.handleClose}
	        element={this.state.elementToEdit}
	        refreshElements={this.props.refreshElements}
        	type={this.props.type}/>
        <ConfirmDialog
	      	open={this.state.openConfirm}
	        handleClose={this.handleCloseConfirm}
	        confirmAction={this.handleConfirmAction}
	        title={"Conferma cancellazione"}
	        message={"Sei sicuro di procedere con la cancellazione?"}
	      />
      </div>
    );
  }

}