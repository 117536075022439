import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://sporteventmanagement.it/api/sportclass/';

class SportClassService {

	add(sportclass) {
	    return axios.post(API_URL + 'add', sportclass, { headers: authHeader() });
	}

	getAll(){
		return axios.get(API_URL + 'all', { headers: authHeader() });
	}

	delete(sportclass) {
	    return axios.post(API_URL + 'delete', sportclass, { headers: authHeader() });
	}

}

export default new SportClassService();
