import * as React from 'react';
import { DataGrid, itIT } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import { GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid'; 
import Typography from '@mui/material/Typography';
import CustomGridLoadingOverlay from './custom-grid-loading-overlay-component';
import AddPaymentDialog from './add-payment-dialog-component';
import ConfirmDialog from './confirm-dialog-component'
import RegistrationService from "../services/registration-service";

function ActionsButton(props) {
  const { deleteAction, downloadAction } = props;
  
  return (
  	<Grid container direction="row" alignItems="center">
  		<IconButton onClick={downloadAction}><DownloadIcon /></IconButton>
	    {/*<IconButton onClick={deleteAction}><DeleteIcon /></IconButton>*/}
	</Grid>
  );
}

 function CustomToolbar(props) {
  const { addAction } = props;
  return (
    <GridToolbarContainer>
      <Grid container justifyContent="flex-start">
      	  <GridToolbarExport 
	      	csvOptions={{
			    fileName: 'payments',
			    delimiter: ';',
		  	}}
		  />
	  </Grid>
      <Grid container justifyContent="flex-end">
	      <Button variant="contained" endIcon={<AddIcon />} onClick={addAction}>
	        Aggiungi
	      </Button>
	  </Grid>
    </GridToolbarContainer>
  );
}

export default class PaymentsGrid extends React.Component {

  	constructor(props) {
	    super(props);
	    this.state = {
	      open: false,
	      openConfirm: false,
	      openEdit: false,
	      paymentToDelete: ''
	    };
  	}

  handleClickOpenConfirm = () => {
    this.setState({openConfirm: true})
  };

  handleCloseConfirm = () => {
    this.setState({openConfirm: false})
  };
  
  handleConfirmAction = () => {
    this.setState({openConfirm: false})
  };

  handleConfirmAction = async e => {
    e.preventDefault();
    RegistrationService.deletePayment(this.state.paymentToDelete).then(
      response => {
        this.handleCloseConfirm();
		this.props.refreshPayments();
      },
      error => {
        this.handleCloseConfirm();
		this.props.refreshPayments();
      }
    );
  } 
  
  handleClickOpen = () => {
    this.setState({open: true})
  };

  handleClose = () => {
    this.setState({open: false})
  };

  handleCloseEdit = () => {
    this.setState({openEdit: false})
  };
  
  columns = [
	  {
	    field: 'association',
	    headerName: 'Associazione',
	    flex: 1,
	    editable: false,
	    valueGetter: (params) => {
	    	return params.row.association.name
	    }
	  },
	  {
	    field: 'date',
	    headerName: 'Data caricamento',
	    editable: false,
	    flex: 1,
	  },
	  {
	    field: 'actions',
	    headerName: 'Azioni',
	    width: 70,
	    editable: false,
	    renderCell: (params) => {
	      	
	      	const downloadAction = (e) => {
		        e.stopPropagation();
			    const link = document.createElement("a");
			    link.href = params.row.paymentData
			    link.setAttribute('download', "pagamento "+params.row.association.name+".pdf")
			    link.click();
	      	};

	      	const deleteAction = (e) => {
		        e.stopPropagation();
		        this.setState({
		        	openConfirm :true,
		        	paymentToDelete: params.row
		        })
	      	};
	
	      	return <ActionsButton downloadAction={downloadAction} deleteAction={deleteAction}/>;
	    }
	  },
	];
  
  render(){
	  return (
	    <div style={{ height: 450, width: '100%' }}>
	      <Typography variant="h4" gutterBottom component="div" >
	      	 Storico pagamenti
	      </Typography>
	      <DataGrid
	        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
	        rows={this.props.payments}
	        columns={this.columns}
	        pageSize={5}
	        rowsPerPageOptions={[5]}
	        disableSelectionOnClick
	        loading={this.props.payments.length===0}
	        sx={{ marginBottom:'15px'}}
			components={{
	          Toolbar: CustomToolbar,
	          LoadingOverlay: CustomGridLoadingOverlay,
	        }}
	        componentsProps={{
	          toolbar: {addAction:this.handleClickOpen}
	        }}
	      />
	      <AddPaymentDialog
	      	open={this.state.open}
	        handleClose={this.handleClose}
	        refreshPayments={this.props.refreshPayments}
	        competitionId={this.props.competitionId}
	        association={this.props.association}
	      />
	      <ConfirmDialog
	      	open={this.state.openConfirm}
	        handleClose={this.handleCloseConfirm}
	        confirmAction={this.handleConfirmAction}
	        title={"Conferma cancellazione"}
	        message={"Sei sicuro di procedere con la cancellazione?"}
	      />
	    </div>
	  );
  }
}