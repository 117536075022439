import axios from 'axios';
import authHeader from './auth-header';
import AuthService from "./auth-service";

const API_URL = 'https://sporteventmanagement.it/api/gymnast/';

class GymnastService {
  	

	getGymnastBoard() {
		const currentUser = AuthService.getCurrentUser();
	    return axios.post(API_URL + 'allByAssociation', currentUser, { headers: authHeader() });
	}

	addGymnast(gymnast) {
		var data = {
			associationUsername: gymnast.association,
			name: gymnast.name, 
			surname: gymnast.surname, 
			birthDate: gymnast.birthDate, 
			birthCity: gymnast.birthCity, 
			cf: gymnast.cf, 
			genre: gymnast.genre, 
			csenCode: gymnast.csenCode
		}
	    return axios.post(API_URL + 'add', data, { headers: authHeader() });
	}

	deleteGymnast(gymnast) {
	    return axios.post(API_URL + 'delete', gymnast, { headers: authHeader() });
	}

	editGymnast(gymnast) {
		const currentUser = AuthService.getCurrentUser();
	    	var data = {
                        associationUsername: currentUser.username,
                        gymnastId: gymnast.gymnastId,
                        name: gymnast.name,
                        surname: gymnast.surname,
                        birthDate: gymnast.birthDate,
                        birthCity: gymnast.birthCity,
                        genre: gymnast.genre,
                        csenCode: gymnast.csenCode,
                        cf: gymnast.cf
                }

		return axios.post(API_URL + 'edit', data, { headers: authHeader() });
	}

}

export default new GymnastService();
