import * as React from 'react';
import { DataGrid, itIT } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport} from '@mui/x-data-grid'; 
import Typography from '@mui/material/Typography';
import CustomGridLoadingOverlay from './custom-grid-loading-overlay-component';
import EditAssociationDialog from './edit-association-dialog-component';
import ResetPasswordAssociationDialog from './reset-password-association-dialog-component';
import ConfirmDialog from './confirm-dialog-component'
import UserService from "../services/user-service";
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import LockResetIcon from '@mui/icons-material/LockReset';

function ActionsButton(props) {
  const { deleteAction, editAction, resetPassword, switchAction } = props;
  return (
  	<Grid container direction="row" alignItems="center">
		<Switch
		  checked={props.enabled}
		  onChange={switchAction}
		  inputProps={{ 'aria-label': 'controlled' }}
		/>
		<IconButton onClick={editAction}><EditIcon /></IconButton>
		<IconButton onClick={resetPassword}><LockResetIcon /></IconButton>
	    {/*<IconButton onClick={deleteAction}><DeleteIcon /></IconButton>*/}
	</Grid>
  );
}

 function CustomToolbar(props) {
  const { addAction } = props;
  return (
    <GridToolbarContainer>
      <Grid container justifyContent="flex-start">
	      <GridToolbarColumnsButton />
	      <GridToolbarFilterButton />
	      <GridToolbarExport 
	      	csvOptions={{
			    fileName: 'associations',
			    delimiter: ';',
		  	}}
		  />
	  </Grid>
    </GridToolbarContainer>
  );
}

export default class AssociationsGrid extends React.Component {
  	constructor(props) {
	    super(props);
	    this.state = {
	      open: false,
	      openConfirm: false,
	      openEdit: false,
	      openReset: false,
	      associationToDelete: '',
	      associationToEdit:'',
	      associationToSwitch:''
	    };
  	}
  
  handleClickOpenConfirm = () => {
    this.setState({openConfirm: true})
  };

	resetPassword = (associationToSwitch) => {
		associationToSwitch.enabled = !associationToSwitch.enabled
	   UserService.editAssociation(associationToSwitch).then(
	      response => {
			this.props.refreshAssociations();
	      },
	      error => {
			this.props.refreshAssociations();
	      }
	    );
	}

	switchAction = (associationToSwitch) => {
		associationToSwitch.enabled = !associationToSwitch.enabled
	    UserService.updateStatusAssociation(associationToSwitch).then(
	      response => {
			this.props.refreshAssociations();
	      },
	      error => {
			this.props.refreshAssociations();
	      }
	    );
	}

  handleCloseConfirm = () => {
    this.setState({openConfirm: false})
  };
  
  handleConfirmAction = () => {
    this.setState({openConfirm: false})
  };
  
  handleConfirmAction = async e => {
    e.preventDefault();
    UserService.deleteAssociation(this.state.associationToDelete).then(
      response => {
        this.handleCloseConfirm();
		this.props.refreshAssociations();
      },
      error => {
        this.handleCloseConfirm();
		this.props.refreshAssociations();
      }
    );
  } 
  
  handleClickOpen = () => {
    this.setState({open: true})
  };

  handleClose = () => {
    this.setState({open: false})
  };

  handleCloseEdit = () => {
    this.setState({openEdit: false})
  };
  
  handleCloseReset = () => {
    this.setState({openReset: false})
  };

  columns = [
	  {
	    field: 'name',
	    headerName: 'Nome',
	    flex: 1,
	    editable: false,
	  },
	  {
	    field: 'username',
	    headerName: 'Username',
	    editable: false,
	    flex: 1,
	  },
	  {
	    field: 'email',
	    headerName: 'Email',
	    editable: false,
	    flex: 1,
	  },
	  {
	    field: 'piva',
	    headerName: 'P. IVA/Codice Fiscale',
	    editable: false,
	    flex: 1,
	  },
	  {
	    field: 'address',
	    headerName: 'Indirizzo',
	    editable: false,
	    flex: 1,
	  },
	  {
	    field: 'cap',
	    headerName: 'CAP',
	    editable: false,
	    flex: 1,
	  },
	  {
	    field: 'city',
	    headerName: 'Città',
	    editable: false,
	    flex: 1,
	  },
	  {
	    field: 'region',
	    headerName: 'Regione',
	    editable: false,
	    flex: 1,
	  },
	  {
	    field: 'phone',
	    headerName: 'Telefono',
	    editable: false,
	    flex: 1,
	  },
	  {
	    field: 'coach',
	    headerName: 'Coach',
	    editable: false,
	    flex: 1,
	  },
	  {
	    field: 'actions',
	    headerName: 'Azioni',
	    width: 170,
	    editable: false,
	    renderCell: (params) => {
	      	const resetPassword = (e) => {
	        	e.stopPropagation();
	        	this.setState({
	        		associationToEdit: params.row,
		        	openReset :true
		        })

	      	};

	      	const editAction = (e) => {
	        	e.stopPropagation();
	        	this.setState({
	        		associationToEdit: params.row,
		        	openEdit :true
		        })

	      	};
	      
	      	const deleteAction = (e) => {
		        e.stopPropagation();
		        this.setState({
		        	openConfirm :true,
		        	associationToDelete: params.row
		        })
	      	};

	      	const switchAction = (e) => {
		        e.stopPropagation();
		        this.switchAction(params.row)
		        
	      	};

	      	return <ActionsButton enabled={params.row.enabled} switchAction={switchAction} editAction={editAction} resetPassword={resetPassword} deleteAction={deleteAction}/>;
	    }
	  },
	];
  
  render(){
	  return (
	    <div style={{ height: 450, width: '100%' }}>
	      <Typography variant="h4" gutterBottom component="div" >
	      	 Lista Associazioni
	      </Typography>
	      <DataGrid
	        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
	        rows={this.props.associations}
	        columns={this.columns}
	        pageSize={5}
	        rowsPerPageOptions={[5]}
	        disableSelectionOnClick
	        loading={this.props.associations.length===0}
	        sx={{ marginBottom:'15px'}}
			components={{
	          Toolbar: CustomToolbar,
	          LoadingOverlay: CustomGridLoadingOverlay,
	        }}
	        componentsProps={{
	          toolbar: {addAction:this.handleClickOpen}
	        }}
	      />
	      <EditAssociationDialog
	      	association={this.state.associationToEdit}
	      	open={this.state.openEdit}
	        handleClose={this.handleCloseEdit}
	        refreshAssociations={this.props.refreshAssociations}
	      />
	      <ResetPasswordAssociationDialog
	      	association={this.state.associationToEdit}
	      	open={this.state.openReset}
	        handleClose={this.handleCloseReset}
	        refreshAssociations={this.props.refreshAssociations}
	      />
	      <ConfirmDialog
	      	open={this.state.openConfirm}
	        handleClose={this.handleCloseConfirm}
	        confirmAction={this.handleConfirmAction}
	        title={"Conferma cancellazione"}
	        message={"Sei sicuro di procedere con la cancellazione?"}
	      />
	    </div>
	  );
  }
}