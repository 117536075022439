import React from "react";
import JudgesGrid from '../components/judges-grid-component';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import JudgeService from "../services/judge-service";
import AuthService from "../services/auth-service";
import { Navigate } from "react-router-dom";

class Judges extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        	redirect: null,
            showSpinner: false,
            judges: []
        }
    }
    
    async componentDidMount() {
      const currentUser = AuthService.getCurrentUser();
      if ((currentUser && !currentUser.roles.includes("ROLE_SUPERADMIN")) || !currentUser) this.setState({ redirect: "/home" });
	    JudgeService.getAll().then((judgesResponse) => {
	    	var judges = []
	    	judgesResponse.data.forEach(function(element, index) { 
	    		var judge =  { id: index+1, judgeId: element.id, name: element.name, surname: element.surname, city: element.city };
				judges.splice(index, 0, judge);
			})
	    	this.setState({
		  		showSpinner: false,
				judges: judges
		  	})
	    }).catch((errorResponse) => {
	    	console.log(errorResponse)
	    });
	}
	
	refreshJudges = async event => {
		this.setState({
		  	showSpinner: true,
		})
		JudgeService.getAll().then((judgesResponse) => {
	    	var judges = []
	    	judgesResponse.data.forEach(function(element, index) { 
	    		var judge =  { id: index+1, judgeId: element.id, name: element.name, surname: element.surname, city: element.city };
				judges.splice(index, 0, judge);
			})
	    	this.setState({
		  		showSpinner: false,
				judges: judges
		  	})
	    });
	}
	
	render(){
		if (this.state.redirect) {
	      return <Navigate to={this.state.redirect} />
	    }
	  	return (
	  	<div>
            {
                this.state.showSpinner &&
			    <div className="flex items-center justify-center mt-10 text-center px-6 py-6">
			    	<Box sx={{ display: 'flex' }}>
			      		<CircularProgress />
			    	</Box>
		        </div>
				
            }
            {
                !this.state.showSpinner &&
			    <div className="flex items-center justify-center text-center px-6 py-6">
					<JudgesGrid judges={this.state.judges} refreshJudges={this.refreshJudges}/>
		        </div>
            }
        </div>
	  );
	}
}

export default Judges;