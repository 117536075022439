import axios from 'axios';
import authHeader from './auth-header';
import AuthService from "./auth-service";

const API_URL = 'https://sporteventmanagement.it/api/judge/';

class JudgeService {

	addJudge(judge) {
	    return axios.post(API_URL + 'create', judge, { headers: authHeader() });
	}

	getAll(){
		return axios.get(API_URL + 'getAll', { headers: authHeader() });
	}

	deleteJudge(judge) {
	    return axios.post(API_URL + 'delete', judge, { headers: authHeader() });
	}

}

export default new JudgeService();
