import axios from 'axios';
import authHeader from './auth-header';
import AuthService from "./auth-service";

const API_URL = 'https://sporteventmanagement.it/api/discipline/';

class DisciplineService {

	add(discipline) {
	    return axios.post(API_URL + 'add', discipline, { headers: authHeader() });
	}

	getAll(){
		return axios.get(API_URL + 'all', { headers: authHeader() });
	}

	delete(discipline) {
	    return axios.post(API_URL + 'delete', discipline, { headers: authHeader() });
	}

}

export default new DisciplineService();
