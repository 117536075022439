import React, { Component } from "react";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import SportClassService from "../services/class-service";
import CategoryService from "../services/category-service";
import DisciplineService from "../services/discipline-service";
import UnitService from "../services/unit-service";
import AuthService from "../services/auth-service";
import { Navigate } from "react-router-dom";
import ListElement from '../components/list-elements-component'

export default class Configurations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      content: "",
      openAdd: false,
      disciplines: [],
      categories: [],
      classes: [],
      units: []
    };
  }

  handleClickOpenAdd = () => {
    this.setState({openAdd: true})
  };

  handleClose = () => {
    this.setState({openAdd: false})
  };

  componentDidMount() {
      const currentUser = AuthService.getCurrentUser();
      if ((currentUser && !currentUser.roles.includes("ROLE_SUPERADMIN")) || !currentUser) this.setState({ redirect: "/home" });
      DisciplineService.getAll().then((disciplineResponse) => {
        this.setState({
          disciplines: disciplineResponse.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
          showSpinner: false
        })
      }).catch((errorResponse) => {
         this.setState({
            disciplines: [],
            showSpinner: false
          })
      });
      CategoryService.getAll().then((categoryResponse) => {
        this.setState({
          categories: categoryResponse.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
          showSpinner: false
        })
      }).catch((errorResponse) => {
          this.setState({
            categories: [],
            showSpinner: false
          })
      });
      SportClassService.getAll().then((sportClassesResponse) => {
        this.setState({
          classes: sportClassesResponse.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
          showSpinner: false
        })
      }).catch((errorResponse) => {
          this.setState({
            classes: [],
            showSpinner: false
          })
      });
      UnitService.getAll().then((unitResponse) => {
        this.setState({
          units: unitResponse.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
          showSpinner: false
        })
      }).catch((errorResponse) => {
          this.setState({
            units: [],
            showSpinner: false
          })
      });
  }

  refreshDisciplines = async event => {
    this.setState({
        showSpinner: true,
    })
    DisciplineService.getAll().then((disciplineResponse) => {
      this.setState({
        disciplines: disciplineResponse.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
        showSpinner: false
      })
    }).catch((errorResponse) => {
        this.setState({
          disciplines: [],
          showSpinner: false
        })
    });
  }

  refreshCategories = async event => {
    this.setState({
        showSpinner: true,
    })
    CategoryService.getAll().then((categoryResponse) => {
      this.setState({
        categories: categoryResponse.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
        showSpinner: false
      })
    }).catch((errorResponse) => {
        this.setState({
          categories: [],
          showSpinner: false
        })
    });
  }

  refreshSportClasses = async event => {
    this.setState({
        showSpinner: true,
    })
    SportClassService.getAll().then((sportClassesResponse) => {
      this.setState({
        classes: sportClassesResponse.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
        showSpinner: false
      })
    }).catch((errorResponse) => {
        this.setState({
          classes: [],
          showSpinner: false
        })
    });
  }

  refreshUnits = async event => {
    this.setState({
        showSpinner: true,
    })
    UnitService.getAll().then((unitResponse) => {
      this.setState({
        units: unitResponse.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
        showSpinner: false
      })
    }).catch((errorResponse) => {
        this.setState({
          units: [],
          showSpinner: false
        })
    });
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }
    return (
      <div>
        <header>
          <center>
            <h3>Gestione Discipline/Classi/Categorie</h3>
          </center>
          <Container sx={{ py: 2 }} >
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Grid container justifyContent="center">
                  <ListElement messageButton="Aggiungi Disciplina" elements={this.state.disciplines} refreshElements={this.refreshDisciplines} type="discipline"/>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container justifyContent="center">
                  <ListElement messageButton="Aggiungi Classe/Attrezzo" elements={this.state.classes} refreshElements={this.refreshSportClasses} type="sportClass"/>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container justifyContent="center">
                  <ListElement messageButton="Aggiungi Categoria" elements={this.state.categories} refreshElements={this.refreshCategories} type="category"/>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container justifyContent="center">
                  <ListElement messageButton="Aggiungi Unità competitiva" elements={this.state.units} refreshElements={this.refreshUnits} type="units"/>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </header>
      </div>
    );
  }
}