import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import JudgeService from "../services/judge-service";

export default class AddJudgeDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      surname: '',
      city: '',
      showSpinner: false
    };
  }
  
  addJudge = async e => {
    e.preventDefault();
    this.setState({showSpinner: true})
    var judgeToCreate={
      	name: this.state.name,
      	surname: this.state.surname,
        city: this.state.city
    }

	JudgeService.addJudge(judgeToCreate).then(
        response => {
            this.setState({showSpinner: false})
    		this.props.handleClose();
			this.props.refreshJudges();
        },
        error => {
          this.setState({showSpinner: false})
    		this.props.handleClose();
			this.props.refreshJudges();
        }
    );
  }  
  
  render(){
	  return (
	      <Dialog
	        open={this.props.open}
	        aria-labelledby="alert-dialog-title"
	        aria-describedby="alert-dialog-description"
	      >
	        <DialogTitle id="alert-dialog-title">
	          <b>Aggiungi Giudice</b>
	        </DialogTitle>
	        <DialogContent>
	          <TextField
	            autoFocus
	            margin="dense"
	            id="name"
	            label="Nome"
	            type="text"
	            fullWidth
	            variant="standard"
	            onChange={ (e) =>{ this.setState({ name: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="surname"
	            label="Cognome"
	            type="text"
	            fullWidth
	            variant="standard"
	            onChange={ (e) =>{ this.setState({ surname: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="city"
	            label="Città"
	            type="text"
	            fullWidth
	            variant="standard"
	            onChange={ (e) =>{ this.setState({ city: e.target.value });}}
	          />
	        </DialogContent>
	        <DialogActions>
	          <Button onClick={this.props.handleClose} disabled={this.state.showSpinner}>Close</Button>
	          <Button onClick={this.addJudge} disabled={this.state.showSpinner}>Add</Button>
	          {
	                this.state.showSpinner &&
			    	<Box sx={{ marginLeft:'5px', display: 'flex' }}>
			      		<CircularProgress />
			    	</Box>
	          }
	        </DialogActions>
	      </Dialog>
	  );
  }
}