import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://sporteventmanagement.it/api/category/';

class CategoryService {

	add(category) {
	    return axios.post(API_URL + 'add', category, { headers: authHeader() });
	}

	getAll(){
		return axios.get(API_URL + 'all', { headers: authHeader() });
	}

	delete(category) {
	    return axios.post(API_URL + 'delete', category, { headers: authHeader() });
	}

}

export default new CategoryService();
