import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import AuthService from "../services/auth-service";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = value => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = value => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = value => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangePiva = this.onChangePiva.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onChangeCap = this.onChangeCap.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeRegion = this.onChangeRegion.bind(this);
    this.onChangeCoach = this.onChangeCoach.bind(this);
    this.onChangeCode = this.onChangeCode.bind(this);

    this.state = {
      username: "",
      email: "",
      password: "",
      name: "",
      piva: "",
      address: "",
      cap: "",
      city: "",
      region: "",
      coach: "",
      phone: "",
      code: "",
      successful: false,
      loading: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  onChangeName(e) {
    this.setState({
      name: e.target.value
    });
  }

  onChangePiva(e) {
    this.setState({
      piva: e.target.value
    });
  }

  onChangeAddress(e) {
    this.setState({
      address: e.target.value
    });
  }

  onChangeCity(e) {
    this.setState({
      city: e.target.value
    });
  }

  onChangeCap(e) {
    this.setState({
      cap: e.target.value
    });
  }

  onChangePhone(e) {
    this.setState({
      phone: e.target.value
    });
  }

  onChangeRegion(e) {
    this.setState({
      region: e.target.value
    });
  }

  onChangeCoach(e) {
    this.setState({
      coach: e.target.value
    });
  }

  onChangeCode(e) {
    this.setState({
      code: e.target.value
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false,
      loading: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.register(
        this.state.username,
        this.state.email,
        this.state.password,
        this.state.name,
        this.state.piva,
        this.state.address,
        this.state.cap,
        this.state.city,
        this.state.region,
        this.state.coach,
        this.state.phone,
        this.state.code
      ).then(
        response => {
          this.setState({
            message: response.data.message,
            successful: true,
            loading: false
          });
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage,
            loading: false
          });
        }
      );
    }
  }

  render() {
    return (
      <div className="col-md-16">
        <div className="card card-container">
          <img
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            alt="profile-img"
            className="profile-img-card"
          />

          <Form
            onSubmit={this.handleRegister}
            ref={c => {
              this.form = c;
            }}
          >
            {!this.state.successful && (
              <div>
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="username"
                    value={this.state.username}
                    onChange={this.onChangeUsername}
                    validations={[required, vusername]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChangeEmail}
                    validations={[required, email]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <Input
                    type="password"
                    className="form-control"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                    validations={[required, vpassword]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="name">Nome Associazione</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="name"
                    value={this.state.name}
                    onChange={this.onChangeName}
                    validations={[required]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="code">Codice affiliazione</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="code"
                    value={this.state.code}
                    onChange={this.onChangeCode}
                    validations={[required]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="piva">P. IVA/Cod. Fiscale Associazione</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="piva"
                    value={this.state.piva}
                    onChange={this.onChangePiva}
                    validations={[required]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="region">Regione</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="region"
                    value={this.state.region}
                    onChange={this.onChangeRegion}
                    validations={[required]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="city">Città</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="city"
                    value={this.state.city}
                    onChange={this.onChangeCity}
                    validations={[required]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="address">Indirizzo</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="address"
                    value={this.state.address}
                    onChange={this.onChangeAddress}
                    validations={[required]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="cap">CAP</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="cap"
                    value={this.state.cap}
                    onChange={this.onChangeCap}
                    validations={[required]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="phone">Telefono</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="phone"
                    value={this.state.phone}
                    onChange={this.onChangePhone}
                    validations={[required]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="coach">Coach</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="coach"
                    value={this.state.coach}
                    onChange={this.onChangeCoach}
                    validations={[required]}
                  />
                </div>

                <div class="d-grid gap-2 col-6 mt-4 mx-auto">
                  <button
                    className="btn btn-primary "
                    disabled={this.state.loading || !this.state.username || !this.state.email || !this.state.password || !this.state.piva || !this.state.name || !this.state.code || !this.state.region || !this.state.city || !this.state.address || !this.state.cap || !this.state.coach || !this.state.phone}
                  >
                    {this.state.loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Sign Up</span>
                  </button>
                </div>
              </div>
            )}

            {this.state.message && (
              <div className="form-group mt-2">
                <div
                  className={
                    this.state.successful
                      ? "alert alert-success"
                      : "alert alert-danger"
                  }
                  role="alert"
                >
                  {this.state.message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </div>
      </div>
    );
  }
}