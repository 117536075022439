import React, { Component } from "react";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddCompetitionDialog from '../components/add-competition-component'
import CompetitionService from "../services/competition-service";
import AuthService from "../services/auth-service";
import Container from '@mui/material/Container';
import { Navigate } from "react-router-dom";

export default class Competitions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      content: "",
      openAdd: false,
      competitions: [],
      currentUser: null,
      showSpinner: false
    };
  }

  handleClickOpenAdd = () => {
    this.setState({openAdd: true})
  };

  handleClose = () => {
    this.setState({openAdd: false})
  };

  downloadRules = (competition) => () => {
    const link = document.createElement("a");
    link.href = competition.rules
    link.setAttribute('download', "regolamento "+competition.title+".pdf")
    link.click();
  };

  navigateTo = (competition) => () => {
    this.setState({
      redirect: "/competition/"+competition.id
    })
  };

  navigateToRegistration = (competition) => () => {
    this.setState({
      redirect: "/competition/"+competition.id+"/registration"
    })
  };

  componentDidMount() {
      const currentUser = AuthService.getCurrentUser();
      this.setState({
        currentUser: currentUser,
        showSpinner: true
      })
      CompetitionService.getAll().then((competitionsResponse) => {
        if(currentUser && currentUser.roles.includes("ROLE_SUPERADMIN")){
          this.setState({
            competitions: competitionsResponse.data,
            showSpinner: false
          })          
        }else {
          this.setState({
            competitions: competitionsResponse.data.filter((comp)=>{return comp.hidden==false}),
            showSpinner: false
          })  
        }
      }).catch((errorResponse) => {
        console.log(errorResponse)
      });
  }

  refreshCompetitions = async event => {
    this.setState({
        showSpinner: true,
    })
    CompetitionService.getAll().then((competitionsResponse) => {
      if(this.state.currentUser && this.state.currentUser.roles.includes("ROLE_SUPERADMIN")){
        this.setState({
          competitions: competitionsResponse.data,
          showSpinner: false
        })
      }else {
        this.setState({
          competitions: competitionsResponse.data.filter((comp)=>{return comp.hidden==false}),
          showSpinner: false
        })        
      }

    }).catch((errorResponse) => {
        this.setState({
          competitions: [],
          showSpinner: false
        })
    });
  }

  render() {
      if (this.state.redirect) {
        return <Navigate to={this.state.redirect} />
      }
    const listItems = this.state.competitions.map((competition) => {
        return (
          <Grid item key={competition.title} xs={12} sm={6} md={4}>
            <Card
              sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            >
              <CardMedia
                component="img"
                sx={{
                  pt: '10.25%',
                }}
                component='img' src={`${competition.poster}`}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="h2">
                  {competition.title}
                </Typography>
                <Typography>
                  Città: {competition.city}
                </Typography>
                { !competition.dateEnd && (
                  <Typography>
                    Data: {competition.date}
                  </Typography>
                )}
                { competition.dateEnd && (
                  <div>
                    <Typography>
                      Data inizio: {competition.date}
                    </Typography>
                    <Typography>
                      Data fine: {competition.dateEnd}
                    </Typography>
                  </div>
                )}
                { competition.registrationEnd && (
                  <Typography>
                      Chiusura iscrizioni: {competition.registrationEnd}
                  </Typography>
                )}
              </CardContent>

              {this.state.currentUser && (this.state.currentUser.roles.includes("ROLE_SUPERADMIN") || (this.state.currentUser.roles.includes("ROLE_ADMIN") && competition.admins.map(function(admin){return admin['email']}).includes(this.state.currentUser.email))) && (
                <CardActions>
                  <Button size="small" onClick={this.navigateTo(competition)}>Gestisci</Button>
                </CardActions>
              )}
              {this.state.currentUser && (this.state.currentUser.roles.includes("ROLE_SUPERADMIN") || ((this.state.currentUser.roles.includes("ROLE_USER") || (this.state.currentUser.roles.includes("ROLE_ADMIN") && competition.admins.map(function(admin){return admin['email']}).includes(this.state.currentUser.email))) && competition.enabled)) && (
                <CardActions>
                  <Button size="small" onClick={this.navigateToRegistration(competition)}>Iscrizioni</Button>
                </CardActions>
              )}
              {this.state.currentUser && competition.rules && (
                <CardActions>
                  <Button size="small" onClick={this.downloadRules(competition)}>Scarica Regolamento</Button>
                </CardActions>
              )}
            </Card>
          </Grid>
        );
    });
    return (
      <div className="container">
        { this.state.showSpinner && (
          <div className="flex items-center justify-center mt-10 text-center px-6 py-6">
            <Box sx={{ py: 8 }}>
                <CircularProgress />
            </Box>
          </div>
        )}
        { !this.state.showSpinner && (
          <div>
            <header className="jumbotron">
              <Container sx={{ py: 8 }} maxWidth="md">
                {this.state.currentUser && this.state.currentUser.roles.includes("ROLE_SUPERADMIN") && ( 
                  <IconButton color="primary" aria-label="Crea nuova competizione" onClick={this.handleClickOpenAdd}>
                    Crea Nuova Competizione
                    <AddBoxIcon />
                  </IconButton>
                )}
                <Grid container spacing={4}>
                  {listItems}
                </Grid>
              </Container>
            </header>
            <AddCompetitionDialog
              open={this.state.openAdd}
              handleClose={this.handleClose}
              refreshCompetitions={this.refreshCompetitions}
            />
          </div>
        )}
      </div>
    );
  }
}
