import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';


export default class InputFileUpload extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      messageButton: props.messageButton,
      selectedImage: props.fileContent
    };
  }

  render(){
    return (
      <div>
        {this.state.selectedImage && this.props.type == ".png" &&(
          <div>
            <img
              alt="not found"
              width={"250px"}
              src={URL.createObjectURL(this.state.selectedImage)}
            />
            <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={() => {
              this.setState({ selectedImage: null }); this.props.clear()}
            }>
            </Button>
          </div>
        )}
        {this.state.selectedImage && this.props.type == ".pdf" && (
          <div>
            <Typography display="inline">
              {this.state.selectedImage.name}
            </Typography>
            <Button sx={{ marginTop: '15px' }} variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={() => {
              this.setState({ selectedImage: null }); this.props.clear()}
            }>
            </Button>
          </div>
        )}
        <Button sx={{ marginTop: '15px' }} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
          {this.state.messageButton}
          <input
            type="file"
            name="locandina"
            accept={this.props.type}
            style={{width:'0px', height:'0px'}}
            onChange={(event) => {
              this.setState({ selectedImage: event.target.files[0]});
              this.props.getFileFromUpload(event)
            }}
          />
        </Button>
      </div>
    );
  }

}