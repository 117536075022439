import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import GymnastService from "../services/gymnast-service";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import AuthService from "../services/auth-service";
import UserService from "../services/user-service";

export default class AddGymnastDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      surname: '',
      birthDate: '',
      birthCity: '',
      cf: '',
      genre: '',
      csenCode: '',
      association: '',
      isAdmin: false,
      currentUser:'',
      associations:[],
      showSpinner: false
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();
    this.setState({
    	currentUser: user,
    	association: user.username
  	});
    if (user && user.roles.includes("ROLE_SUPERADMIN")) {
		UserService.getAll().then(
	        response => {
		      	this.setState({
		        	associations: response.data,
		        	isAdmin: true
		      	});
	        },
	        error => {
		      	console.log(error)
	        }
	    );

    }
  }
  
  addGymnast = async e => {
    e.preventDefault();
    this.setState({showSpinner: true})
    var gymnastToCreate={
      	name: this.state.name,
      	surname: this.state.surname,
        birthDate: this.state.birthDate,
        birthCity: this.state.birthCity,
        cf: this.state.cf,
        genre: this.state.genre,
        csenCode: this.state.csenCode,
        association: this.state.association
    }

	GymnastService.addGymnast(gymnastToCreate).then(
        response => {
            this.setState({showSpinner: false})
    		this.props.handleClose();
			this.props.refreshGymnasts();
        },
        error => {
          this.setState({showSpinner: false})
    		this.props.handleClose();
			this.props.refreshGymnasts();
        }
    );
  }  
  
  render(){
	  return (
	      <Dialog
	        open={this.props.open}
	        aria-labelledby="alert-dialog-title"
	        aria-describedby="alert-dialog-description"
	      >
	        <DialogTitle id="alert-dialog-title">
	          <b>Aggiungi Atleta</b>
	        </DialogTitle>
	        <DialogContent>
		        {this.state.isAdmin && ( <FormControl fullWidth>
				  <InputLabel id="associationInputLabel">Associazione</InputLabel>
				  <Select
				    labelId="associationInputLabel"
				    id="associationInput"
				    value={this.state.association}
				    label="Associazione"
				    onChange={ (e) =>{ this.setState({ association: e.target.value });}}
				  >
				    {this.state.associations.map((data) => (
					  <MenuItem key={data.username} value={data.username}>{data.name}</MenuItem>
					))}
				  </Select>
				</FormControl>
				)}
	          <TextField
	            autoFocus
	            margin="dense"
	            id="name"
	            label="Nome"
	            type="text"
	            fullWidth
	            variant="standard"
	            onChange={ (e) =>{ this.setState({ name: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="surname"
	            label="Cognome"
	            type="text"
	            fullWidth
	            variant="standard"
	            onChange={ (e) =>{ this.setState({ surname: e.target.value });}}
	          />
	          <LocalizationProvider dateAdapter={AdapterDayjs}>
	             	<DemoContainer components={['DatePicker']}>
		          		<DatePicker
			          	  id="birthDate"
				          label="Data di nascita"
				          inputFormat="DD/MM/YYYY"
				          format="DD/MM/YYYY"
				          onChange={(newValue) =>{ 
				          	this.setState({ birthDate: newValue.format("DD/MM/YYYY") });
				          }}
			        	/>
			        </DemoContainer>
			  </LocalizationProvider>
	          <TextField
	            margin="dense"
	            id="birthCity"
	            label="Luogo di nascita"
	            type="text"
	            fullWidth
	            variant="standard"
	            onChange={ (e) =>{ this.setState({ birthCity: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="cf"
	            label="Codice Fiscale"
	            type="text"
	            fullWidth
	            variant="standard"
	            onChange={ (e) =>{ this.setState({ cf: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="csenCode"
	            label="Codice Tessera"
	            type="text"
	            fullWidth
	            variant="standard"
	            onChange={ (e) =>{ this.setState({ csenCode: e.target.value });}}
	          />
	          <FormControl fullWidth>
				  <InputLabel id="genre-label">Genere</InputLabel>
				  <Select
				    labelId="genre-label"
				    id="genre"
				    value={this.state.genre}
				    label="Genere"
				    onChange={(e) =>{ this.setState({ genre: e.target.value });} }
				  >
				    <MenuItem value="M">Maschio</MenuItem>
				    <MenuItem value="F">Femmina</MenuItem>
				  </Select>
				</FormControl>
	        </DialogContent>
	        <DialogActions>
	          <Button onClick={this.props.handleClose} disabled={this.state.showSpinner}>Chiudi</Button>
	          <Button onClick={this.addGymnast} disabled={this.state.showSpinner}>Aggiungi</Button>
	          {
	                this.state.showSpinner &&
			    	<Box sx={{ marginLeft:'5px', display: 'flex' }}>
			      		<CircularProgress />
			    	</Box>
	          }
	        </DialogActions>
	      </Dialog>
	  );
  }
}