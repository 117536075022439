import React from "react";
import GymnastsGrid from '../components/gymnasts-grid-component';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import GymnastService from "../services/gymnast-service";
import AuthService from "../services/auth-service";
import { Navigate } from "react-router-dom";

class Gymnasts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        	redirect: null,
            showSpinner: false,
            gymnasts: []
        }
    }
    
    async componentDidMount() {
      const currentUser = AuthService.getCurrentUser();
      if ((currentUser && !currentUser.roles.includes("ROLE_SUPERADMIN") && !currentUser.roles.includes("ROLE_USER")) || !currentUser) this.setState({ redirect: "/home" });
	    GymnastService.getGymnastBoard().then((gymnastsResponse) => {
	    	var gymnasts = []
	    	gymnastsResponse.data.forEach(function(element, index) { 
	    		var gymnast =  { id: index+1, gymnastId: element.gymnastId, association: element.association, name: element.name, surname: element.surname, cf: element.cf, csenCode: element.csenCode, genre: element.genre, birthCity: element.birthCity, birthDate: element.birthDate };
				gymnasts.splice(index, 0, gymnast);
			})
	    	this.setState({
		  		showSpinner: false,
				gymnasts: gymnasts
		  	})
	    }).catch((errorResponse) => {
	    	console.log(errorResponse)
	    });
	}
	
	refreshGymnasts = async event => {
		this.setState({
		  	showSpinner: true,
		})
		GymnastService.getGymnastBoard().then((gymnastsResponse) => {
	    	var gymnasts = []
	    	gymnastsResponse.data.forEach(function(element, index) { 
	    		var gymnast =  { id: index+1, gymnastId: element.gymnastId, association: element.association, name: element.name, surname: element.surname, cf: element.cf, csenCode: element.csenCode, genre: element.genre, birthCity: element.birthCity, birthDate: element.birthDate };
				gymnasts.splice(index, 0, gymnast);
			})
	    	this.setState({
		  		showSpinner: false,
				gymnasts: gymnasts
		  	})
	    });
	}
	
	render(){
		if (this.state.redirect) {
	      return <Navigate to={this.state.redirect} />
	    }
	  return (
	  	<div>
            {
                this.state.showSpinner &&
			    <div className="flex items-center justify-center mt-10 text-center px-6 py-6">
			    	<Box sx={{ display: 'flex' }}>
			      		<CircularProgress />
			    	</Box>
		        </div>
				
            }
            {
                !this.state.showSpinner &&
			    <div className="flex items-center justify-center text-center px-6 py-6">
					<GymnastsGrid gymnasts={this.state.gymnasts} refreshGymnasts={this.refreshGymnasts}/>
		        </div>
            }
        </div>
	  );
	}
}

export default Gymnasts;