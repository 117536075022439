import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import UserService from "../services/user-service";

export default class ResetPasswordAssociationDialog extends React.Component {

  	constructor(props) {
	    super(props);
	    this.state = {
	      name: this.props.association.name,
	      username: this.props.association.username,
	      email: this.props.association.email,
	      password: this.props.association.password,
	      showSpinner: false
	    };
  	}

	componentDidUpdate(prevProps, prevState) {
		if( this.props.association.name !== prevProps.association.name 
			|| this.props.association.username !== prevProps.association.username
			|| this.props.association.email !== prevProps.association.email
			|| this.props.association.password !== prevProps.association.password){
			this.setState({
		      	name: this.props.association.name,
		      	username: this.props.association.username,
		      	email: this.props.association.email,
		      	password: this.props.association.password,
		      	showSpinner: false
			})
		}
	}

  	editAssociation = async e => {
	    e.preventDefault();
	    this.setState({showSpinner: true})
	    var associationToEdit={
	    	username: this.state.username,
	      	name: this.state.name,
	      	email: this.state.email,
	        password: this.state.password,
	    }

		UserService.resetPassword(associationToEdit).then(
	        response => {
	            this.setState({showSpinner: false})
	    		this.props.handleClose();
				this.props.refreshAssociations();
	        },
	        error => {
	            this.setState({showSpinner: false})
	    		this.props.handleClose();
				this.props.refreshAssociations();
	        }
	    );
  	}  
  
  	render(){
	  return (
	      <Dialog
	        open={this.props.open}
	        aria-labelledby="alert-dialog-title"
	        aria-describedby="alert-dialog-description"
	      >
	        <DialogTitle id="alert-dialog-title">
	          <b>Modifica Atleta</b>
	        </DialogTitle>
	        <DialogContent>
	          <TextField
	          	disabled
	            margin="dense"
	            id="name"
	            label="Nome"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.name}
	            onChange={ (e) =>{ this.setState({ name: e.target.value });}}
	          />
	          <TextField
	          	disabled
	            margin="dense"
	            id="username"
	            label="Username"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.username}
	            onChange={ (e) =>{ this.setState({ username: e.target.value });}}
	          />
	          <TextField
	          	disabled
	            margin="dense"
	            id="email"
	            label="Email"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.email}
	            onChange={ (e) =>{ this.setState({ email: e.target.value });}}
	          />
	          <TextField
	          	autoFocus
	            margin="dense"
	            id="password"
	            label="Nuova password"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.password}
	            onChange={ (e) =>{ this.setState({ password: e.target.value });}}
	          />
	        </DialogContent>
	        <DialogActions>
	          <Button onClick={this.props.handleClose} disabled={this.state.showSpinner}>Close</Button>
	          <Button onClick={this.editAssociation} disabled={this.state.showSpinner}>Edit</Button>
	          {
	                this.state.showSpinner &&
			    	<Box sx={{ marginLeft:'5px', display: 'flex' }}>
			      		<CircularProgress />
			    	</Box>
	          }
	        </DialogActions>
	      </Dialog>
	  );
  }
}