import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://sporteventmanagement.it/api/registration/';

class RegistrationService {

	add(registration) {
	    return axios.post(API_URL + 'add', registration, { headers: authHeader() });
	}

	edit(registration) {
	    return axios.post(API_URL + 'edit', registration, { headers: authHeader() });
	}

	getAll(competitionId){
		return axios.get(API_URL + 'all/'+competitionId, { headers: authHeader() });
	}

	delete(registration) {
	    return axios.post(API_URL + 'delete', registration, { headers: authHeader() });
	}

	addPayment(payment, competitionId) {
	    return axios.post(API_URL + 'addPayment/'+competitionId, payment, { headers: authHeader() });
	}

	getPayments(association, competitionId) {
	    return axios.post(API_URL + 'getPayments/'+competitionId, association, { headers: authHeader() });
	}

	getAllPayments(competitionId){
		return axios.get(API_URL + 'allPayments/'+competitionId, { headers: authHeader() });
	}

	get(association, competitionId) {
	    return axios.post(API_URL + 'allByAssociation/'+competitionId, association, { headers: authHeader() });
	}

}

export default new RegistrationService();
