import React, { useState } from "react";
import GroupIcon from '@mui/icons-material/Group';
import Person3Icon from '@mui/icons-material/Person3';
import Person4Icon from '@mui/icons-material/Person4';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import Divider from '@mui/material/Divider';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';

const CompetitionCostsSummary = (props) => {
  const [competition, setCompetition] = useState(props.competition);

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
      }}
    >
      { competition.unitCost && (<ListItem>
        <ListItemAvatar>
          <Avatar>
            <Person4Icon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Singolo" secondary={`${competition.unitCost}€ / ${competition.secondUnitCost}€ / ${competition.thirdUnitCost}€`}/>
      </ListItem>)}
      <Divider variant="inset" component="li" />
      { competition.coupleCost && (<ListItem>
        <ListItemAvatar>
          <Avatar>
            <Person4Icon /><Person3Icon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Doppio" secondary={`${competition.coupleCost}€ / ${competition.secondCoupleCost}€ / ${competition.thirdCoupleCost}€`} />
      </ListItem> )}
      <Divider variant="inset" component="li" />
      { competition.groupCost && (<ListItem>
        <ListItemAvatar>
          <Avatar>
            <GroupIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Gruppo" secondary={`${competition.groupCost}€ / ${competition.secondGroupCost}€ / ${competition.thirdGroupCost}€`} />
      </ListItem> )}
      { competition.fixedGroupCost && (<ListItem>
        <ListItemAvatar>
          <Avatar>
            <GroupIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Gruppo" secondary={`${competition.fixedGroupCost}€ ${competition.fixedGroupComponents} Atleti / ${competition.addFixedGroupCost}€ Atleta Extra`} />
      </ListItem> )}
      <Divider variant="inset" component="li" />
    </List>
  );
};

export default CompetitionCostsSummary;