import React, { useState, useEffect } from "react";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import CompetitionService from "../services/competition-service";
import AuthService from "../services/auth-service";
import { Navigate, useParams  } from "react-router-dom";
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import RegistrationGrid from '../components/registration-grid-component';
import GymnastService from "../services/gymnast-service";
import RegistrationService from "../services/registration-service";
import CompetitionCostsSummary from '../components/competition-costs-summary-component';
import PaymentsGrid from '../components/payments-grid-component';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

export default function Registation(){
  const params = useParams()
  const [competition,setCompetition] = useState()
  const [showSpinner,setShowSpinner] = useState(false)
  const [gymnasts,setGymnasts] = useState([])
  const [matches,setMatches] = useState([])
  const [elements,setElements] = useState([])
  const [association,setAssociation] = useState()
  const [redirect,setRedirect] = useState()
  const [payments,setPayments] = useState([])

  useEffect(() => {
    refreshCompetition();
  },[]);

  function downloadRules (competition)  {
    const link = document.createElement("a");
    link.href = competition.rules
    link.setAttribute('download', "regolamento "+competition.title+".pdf")
    link.click();
  };

  useEffect(() => {
    if(association){
      refreshPayments()
    }
  }, [association]);

  async function refreshPayments () {
    if(association.roles.includes("ROLE_SUPERADMIN") || association.roles.includes("ROLE_ADMIN")){
      RegistrationService.getAllPayments(params.competitionId).then((competitionsResponse) => {
        setPayments(competitionsResponse.data)
        setShowSpinner(false)
      }).catch((errorResponse) => {
        setPayments([])
        setShowSpinner(false)
      });
    }else{
      RegistrationService.getPayments(association, params.competitionId).then((competitionsResponse) => {
        setPayments(competitionsResponse.data)
        setShowSpinner(false)
      }).catch((errorResponse) => {
        setPayments([])
        setShowSpinner(false)
      });     
    }

  }

  async function refreshCompetition () {
    setShowSpinner(true)
    const user = AuthService.getCurrentUser();
    if ((user && !user.roles.includes("ROLE_SUPERADMIN") && !user.roles.includes("ROLE_ADMIN") && !user.roles.includes("ROLE_USER")) || !user) setRedirect("/home");
    setAssociation(user)
    GymnastService.getGymnastBoard().then((gymnastsResponse) => {
      setGymnasts(gymnastsResponse.data)
    }).catch((errorResponse) => {
      console.log(errorResponse)
    });
    CompetitionService.get(params.competitionId).then((competitionsResponse) => {
      setCompetition(competitionsResponse.data)
      setMatches(competitionsResponse.data.matches)
      setShowSpinner(false)
    }).catch((errorResponse) => {
      setCompetition([])
      setShowSpinner(false)
    });
    if (user && (user.roles.includes("ROLE_SUPERADMIN") || user.roles.includes("ROLE_ADMIN"))) {
      RegistrationService.getAll(params.competitionId).then((registrationResponse) => {
        var elems = []
        registrationResponse.data.forEach(function(element, index) {
        var registration =  { id: index+1, registrationId: element.id, number: element.number, choreographyName: element.choreographyName, association: element.association, gymnasts: element.gymnasts, discipline: element.discipline, category: element.category, sportClass: element.sportClass, unit: element.unit};
          elems.splice(index, 0, registration);
        })
        setElements(elems)
      }).catch((errorResponse) => {
        console.log(errorResponse)
      });     
    }else{
      RegistrationService.get(user, params.competitionId).then((registrationResponse) => {
        var elems = []
        registrationResponse.data.forEach(function(element, index) {
        var registration =  { id: index+1, registrationId: element.id, number: element.number, choreographyName: element.choreographyName, gymnasts: element.gymnasts, discipline: element.discipline, category: element.category, sportClass: element.sportClass, unit: element.unit};
          elems.splice(index, 0, registration);
        })
        setElements(elems)
      }).catch((errorResponse) => {
        console.log(errorResponse)
      });
    }

  }

  if (redirect) {
    return <Navigate to={redirect} />
  }
  return (  
    <div className="container">
        { showSpinner &&
            <div className="flex items-center justify-center mt-10 text-center px-6 py-6">
              <Box sx={{ display: 'flex' }}>
                  <CircularProgress />
              </Box>
            </div>
        }
        { competition &&
          <div>
            <Typography gutterBottom variant="h3" component="h2">
              {competition.title}
            </Typography>
            <Grid container spacing={8}>
              <Grid item key="poster" xs={4} sm={4} md={4}>
                <Img alt="complex" src={competition.poster}/>
              </Grid>
              <Grid item key="info" xs={4} sm={4} md={4}>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h5">
                      Città: {competition.city}
                    </Typography>
                    <Typography variant="h4">
                      {competition.date}
                    </Typography>
                    <CompetitionCostsSummary competition={competition}/>
                  </CardContent>
                  {competition.rules && (
                    <CardActions>
                      <IconButton size="small" onClick={() =>downloadRules(competition)}><DownloadIcon />Scarica Regolamento</IconButton>
                    </CardActions>
                  )}            
              </Grid>
              <Grid item key="info" xs={4} sm={4} md={4}>
                <PaymentsGrid payments={payments} refreshPayments={refreshPayments} competitionId={params.competitionId} association={association}/>
              </Grid>
              <Grid item key="discipline" xs={12} sm={12} md={12}>
                <RegistrationGrid isAdmin={association.roles.includes("ROLE_SUPERADMIN") || association.roles.includes("ROLE_ADMIN")} competition={competition} competitionId={params.competitionId} elements={elements} gymnasts={gymnasts} matches={matches} refreshCompetition={refreshCompetition}/>
              </Grid>
            </Grid>
          </div>
        }
    </div>
  );

}