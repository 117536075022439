import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://sporteventmanagement.it/api/user/';

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'all', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }

  getSuperAdminBoard() {
    return axios.get(API_URL + 'superadmin', { headers: authHeader() });
  }

  deleteUser(user) {
    return axios.post(API_URL + 'delete', user, { headers: authHeader() });
  }

  getAll() {
    return axios.get(API_URL + 'all', { headers: authHeader() });
  }

  getAllAdmins() {
    return axios.get(API_URL + 'allAdmins', { headers: authHeader() });
  }

  addAdmin(admin) {
    return axios.post(API_URL + 'addAdmin', admin, { headers: authHeader() });
  }

  editAssociation(association) {
    return axios.post(API_URL + 'edit', association, { headers: authHeader() });
  }

  updateStatusAssociation(association) {
    return axios.post(API_URL + 'updateStatus', association, { headers: authHeader() });
  }

  resetPassword(association) {
    return axios.post(API_URL + 'resetPassword', association, { headers: authHeader() });
  }
}

export default new UserService();
