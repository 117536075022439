import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import * as React from 'react';

export default class ConfirmDialog extends React.Component {

  constructor(props) {
    super(props);
  }
  
  render(){
	  return (
	      <Dialog
	        open={this.props.open}
	        aria-labelledby="alert-dialog-title"
	        aria-describedby="alert-dialog-description"
	      >
	        <DialogTitle id="alert-dialog-title">
	          <b>{this.props.title}</b>
	        </DialogTitle>
	        <DialogContent>
	          <DialogContentText id="alert-dialog-description">
	            {this.props.message}
	          </DialogContentText>
	        </DialogContent>
	        <DialogActions>
	          <Button onClick={this.props.handleClose}> Chiudi </Button>
	          <Button onClick={this.props.confirmAction}>Conferma</Button>
	        </DialogActions>
	      </Dialog>
	  );
  }
}