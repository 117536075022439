import * as React from 'react';
import { DataGrid, itIT } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport} from '@mui/x-data-grid'; 
import Typography from '@mui/material/Typography';
import CustomGridLoadingOverlay from './custom-grid-loading-overlay-component';
import AddGymnastDialog from './add-gymnast-dialog-component';
import EditGymnastDialog from './edit-gymnast-dialog-component';
import ConfirmDialog from './confirm-dialog-component'
import GymnastService from "../services/gymnast-service";


function ActionsButton(props) {
  const { deleteAction, editAction } = props;
  
  return (
  	<Grid container direction="row" alignItems="center">
		<IconButton onClick={editAction}><EditIcon /></IconButton>
	    <IconButton onClick={deleteAction}><DeleteIcon /></IconButton>
	</Grid>
  );
}

 function CustomToolbar(props) {
  const { addAction } = props;
  return (
    <GridToolbarContainer>
      <Grid container justifyContent="flex-start">
	      <GridToolbarColumnsButton />
	      <GridToolbarFilterButton />
	      <GridToolbarExport 
	      	csvOptions={{
			    fileName: 'gymnasts',
			    delimiter: ';',
		  	}}
		  />
	  </Grid>
      <Grid container justifyContent="flex-end">
	      <Button variant="contained" endIcon={<AddIcon />} onClick={addAction}>
	        Aggiungi
	      </Button>
	  </Grid>
    </GridToolbarContainer>
  );
}

export default class GymnastsGrid extends React.Component {

  	constructor(props) {
	    super(props);
	    this.state = {
	      open: false,
	      openConfirm: false,
	      openEdit: false,
	      gymnastToDelete: '',
	      gymnastToEdit:''
	    };
  	}
  
  handleClickOpenConfirm = () => {
    this.setState({openConfirm: true})
  };

  handleCloseConfirm = () => {
    this.setState({openConfirm: false})
  };
  
  handleConfirmAction = () => {
    this.setState({openConfirm: false})
  };
  
  handleConfirmAction = async e => {
    e.preventDefault();
    GymnastService.deleteGymnast(this.state.gymnastToDelete).then(
      response => {
        this.handleCloseConfirm();
		this.props.refreshGymnasts();
      },
      error => {
        this.handleCloseConfirm();
		this.props.refreshGymnasts();
      }
    );
  } 
  
  handleClickOpen = () => {
    this.setState({open: true})
  };

  handleClose = () => {
    this.setState({open: false})
  };

  handleCloseEdit = () => {
    this.setState({openEdit: false})
  };
  
  columns = [
  	{
	    field: 'gymnastId',
	    headerName: 'ID',
	    width: 70 
	  },
	  {
	    field: 'association',
	    headerName: 'Associazione',
	    flex: 1,
	    editable: false,
	  },
	  {
	    field: 'name',
	    headerName: 'Nome',
	    flex: 1,
	    editable: false,
	  },
	  {
	    field: 'surname',
	    headerName: 'Cognome',
	    editable: false,
	    flex: 1,
	  },
	  {
	    field: 'cf',
	    headerName: 'Codice Fiscale',
	    editable: false,
	    flex: 1,
	  },
	  {
	    field: 'genre',
	    headerName: 'Genere',
	    width: 70,
	    editable: false,
	  },
	  {
	    field: 'csenCode',
	    headerName: 'Codice Tessera',
	    editable: false,
	  },
	  {
	    field: 'birthDate',
	    headerName: 'Data di nascita',
	    editable: false,
	  },
	  {
	    field: 'actions',
	    headerName: 'Azioni',
	    width: 100,
	    editable: false,
	    renderCell: (params) => {
	      	const editAction = (e) => {
	        	e.stopPropagation();
	        	this.setState({
	        		gymnastToEdit: params.row,
		        	openEdit :true
		        })

	      	};
	      
	      	const deleteAction = (e) => {
		        e.stopPropagation();
		
		        this.setState({
		        	openConfirm :true,
		        	gymnastToDelete: params.row
		        })
	      	};
	
	      	return <ActionsButton editAction={editAction} deleteAction={deleteAction}/>;
	    }
	  },
	];
  
  render(){
	  return (
	    <div style={{ height: 450, width: '100%' }}>
	      <Typography variant="h4" gutterBottom component="div" >
	      	 Lista Atleti
	      </Typography>
	      <DataGrid
	      	localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
	        rows={this.props.gymnasts}
	        columns={this.columns}
	        pageSize={5}
	        rowsPerPageOptions={[5]}
	        disableSelectionOnClick
	        loading={this.props.gymnasts.length===0}
	        sx={{ marginBottom:'15px'}}
			components={{
	          Toolbar: CustomToolbar,
	          LoadingOverlay: CustomGridLoadingOverlay,
	        }}
	        componentsProps={{
	          toolbar: {addAction:this.handleClickOpen}
	        }}
	      />
	      <AddGymnastDialog
	      	open={this.state.open}
	        handleClose={this.handleClose}
	        refreshGymnasts={this.props.refreshGymnasts}
	      />
	      <EditGymnastDialog
	      	gymnast={this.state.gymnastToEdit}
	      	open={this.state.openEdit}
	        handleClose={this.handleCloseEdit}
	        refreshGymnasts={this.props.refreshGymnasts}
	      />
	      <ConfirmDialog
	      	open={this.state.openConfirm}
	        handleClose={this.handleCloseConfirm}
	        confirmAction={this.handleConfirmAction}
	        title={"Conferma cancellazione"}
	        message={"Sei sicuro di procedere con la cancellazione?"}
	      />
	    </div>
	  );
  }
}