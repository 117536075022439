import axios from 'axios';
import authHeader from './auth-header';
import AuthService from "./auth-service";

const API_URL = 'https://sporteventmanagement.it/api/competition/';

class CompetitionService {

	addCompetition(competition) {
	    return axios.post(API_URL + 'create', competition, { headers: authHeader() });
	}

	deleteCompetition(competition) {
            return axios.post(API_URL + 'delete', competition, { headers: authHeader() });
	}

	createMatch(data) {
	    return axios.post(API_URL + 'createMatch', data, { headers: authHeader() });
	}

	deleteMatch(data) {
	    return axios.post(API_URL + 'deleteMatch', data, { headers: authHeader() });
	}

	getAll(){
		return axios.get(API_URL + 'getAll', { headers: authHeader() });
	}

	getCompetitionRegistrations(competition){
		return axios.get(API_URL + 'get/'+competition.id+'/competitionRegistrations', { headers: authHeader(), responseType: 'blob' });
	}

	getAssociationsRegistrationsPdf(competition){
		return axios.get(API_URL + 'get/'+competition.id+'/associationsRegistrationsPdf', { headers: authHeader(), responseType: 'blob' });
	}

	getAssociationsRegistrationsXlsx(competition){
		return axios.get(API_URL + 'get/'+competition.id+'/associationsRegistrationsExcel', { headers: authHeader(), responseType: 'blob' });
	}

	get(competitionId){
		return axios.get(API_URL + 'get/'+competitionId, { headers: authHeader() });
	}

	editCompetition(competition) {
	    return axios.post(API_URL + 'editCompetition', competition, { headers: authHeader() });
	}

	getPilotsPdf(competition){
		return axios.get(API_URL + 'get/'+competition.id+'/pilotsPdf', { headers: authHeader(), responseType: 'blob' });
	}

	getPilotsXlsx(competition){
		return axios.get(API_URL + 'get/'+competition.id+'/pilotsExcel', { headers: authHeader(), responseType: 'blob' });
	}

	getOutingsPdf(competition){
		return axios.get(API_URL + 'get/'+competition.id+'/outingsPdf', { headers: authHeader(), responseType: 'blob' });
	}

	getOutingsXlsx(competition){
		return axios.get(API_URL + 'get/'+competition.id+'/outingsExcel', { headers: authHeader(), responseType: 'blob' });
	}

	getOutingsByAssociationPdf(competition){
		return axios.get(API_URL + 'get/'+competition.id+'/outingsByAssociationPdf', { headers: authHeader(), responseType: 'blob' });
	}

	getOutingsByAssociationXlsx(competition){
		return axios.get(API_URL + 'get/'+competition.id+'/outingsByAssociationExcel', { headers: authHeader(), responseType: 'blob' });
	}

	getOutingsByCategoryPdf(competition){
		return axios.get(API_URL + 'get/'+competition.id+'/outingsByCategoryPdf', { headers: authHeader(), responseType: 'blob' });
	}

	getOutingsByCategoryXlsx(competition){
		return axios.get(API_URL + 'get/'+competition.id+'/outingsByCategoryExcel', { headers: authHeader(), responseType: 'blob' });
	}
	
	assignNumbers(competition, startNumber){
		return axios.get(API_URL + 'get/'+competition.id+'/assignNumbers/'+startNumber, { headers: authHeader() });
	}

	updateAdmins(competition) {
	    return axios.post(API_URL + 'updateAdmins', competition, { headers: authHeader() });
	}
}

export default new CompetitionService();
