import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CompetitionService from "../services/competition-service";
import AuthService from "../services/auth-service";
import { Navigate, useParams  } from "react-router-dom";
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import CompetitionGrid from '../components/competition-grid-component';
import CompetitionCostsSummary from '../components/competition-costs-summary-component';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import UserService from "../services/user-service";
import AddCompetitionDialog from '../components/add-competition-component'
import ConfirmDialog from '../components/confirm-dialog-component'
import EditNoteIcon from '@mui/icons-material/EditNote';


const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

export default function Competition(){
  const params = useParams()
  const [competition,setCompetition] = useState()
  const [showSpinner,setShowSpinner] = useState(false)
  const [openAdd,setOpenAdd] = useState(false)
  const [openConfirm,setOpenConfirm] = useState(false)
  const [elements,setElements] = useState([])
  const [startNumber,setStartNumber] = useState()
  const [currentUser,setCurrentUser] = useState()
  const [admins,setAdmins] = useState()
  const [allAdmins,setAllAdmins] = useState([])
  const [redirect,setRedirect] = useState()

  useEffect(() => {
    refreshCompetition();
  },[]);

  function downloadRules (competition)  {
    const link = document.createElement("a");
    link.href = competition.rules
    link.setAttribute('download', "regolamento "+competition.title+".pdf")
    link.click();
  };

  function handleClickOpenAdd () {
    setOpenAdd(true)
  };

  function handleClose () {
    setOpenAdd(false)
  };

  function handleOpenConfirm () {
    setOpenConfirm(true)
  };

  function handleCloseConfirm () {
    setOpenConfirm(false)
  };

  function goToRegistration () {
    setRedirect("/competition/"+competition.id+"/registration")
  };

  function handleConfirmAction () {
    setShowSpinner(true)
    CompetitionService.deleteCompetition(competition).then(
      response => {
        setShowSpinner(false)
        setRedirect("/home")
      },
      error => {
        refreshCompetition();
      }
    );
  };

  function switchAction (e) {
      const isChecked = e.target.checked;
      setShowSpinner(true)
      competition.enabled = isChecked
      CompetitionService.editCompetition(competition).then(
        response => {
          refreshCompetition();
        },
        error => {
          refreshCompetition();
        }
      );
  }

  function switchActionHidden (e) {
      const isHidden = e.target.checked;
      setShowSpinner(true)
      competition.hidden = isHidden
      CompetitionService.editCompetition(competition).then(
        response => {
          refreshCompetition();
        },
        error => {
          refreshCompetition();
        }
      );
  }

  async function refreshCompetition () {
    setShowSpinner(true)
    const currentUser = AuthService.getCurrentUser();
    if ((currentUser && !currentUser.roles.includes("ROLE_SUPERADMIN") && !currentUser.roles.includes("ROLE_ADMIN")) || !currentUser) setRedirect("/home");
    setCurrentUser(currentUser)
    CompetitionService.get(params.competitionId).then((competitionsResponse) => {
      setCompetition(competitionsResponse.data)
      setAdmins(competitionsResponse.data.admins)
      var elems = []
      competitionsResponse.data.matches.forEach(function(element, index) {
      var match =  { id: index+1, matchId: element.id, competitionId: element.competitionId, discipline: element.disciplines, category: element.categories, sportClass: element.sportClasses, unit: element.units };
        elems.splice(index, 0, match);
      })
      setElements(elems)
      setShowSpinner(false)
    }).catch((errorResponse) => {
      setCompetition([])
      setShowSpinner(false)
    });
    UserService.getAllAdmins().then((adminsResponse) => {
      setAllAdmins(adminsResponse.data)
      setShowSpinner(false)
    }).catch((errorResponse) => {
      setAllAdmins([])
      setShowSpinner(false)
    });
  }

  function getCompetitionRegistrations () {
    CompetitionService.getCompetitionRegistrations(competition).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'ELENCO ATLETI.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }

  function getAssociationsRegistrationsPdf () {
    CompetitionService.getAssociationsRegistrationsPdf(competition).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'ASSOCIAZIONI ISCRITTE.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }

  function getAssociationsRegistrationsXlsx () {
    CompetitionService.getAssociationsRegistrationsXlsx(competition).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'ASSOCIAZIONI ISCRITTE.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }

  function getPilotsPdf () {
    CompetitionService.getPilotsPdf(competition).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'FOGLI PILOTA.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }

  function getPilotsXlsx () {
    CompetitionService.getPilotsXlsx(competition).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'FOGLI PILOTA.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }

  function getOutingsPdf () {
    CompetitionService.getOutingsPdf(competition).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'ELENCO USCITE.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }

  function getOutingsXlsx () {
    CompetitionService.getOutingsXlsx(competition).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'ELENCO USCITE.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }

  function getOutingsByAssociationPdf () {
    CompetitionService.getOutingsByAssociationPdf(competition).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'USCITE PER ASSOCIAZIONE.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }

  function getOutingsByAssociationXlsx () {
    CompetitionService.getOutingsByAssociationXlsx(competition).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'USCITE PER ASSOCIAZIONE.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }

  function getOutingsByCategoryPdf () {
    CompetitionService.getOutingsByCategoryPdf(competition).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'USCITE PER CATEGORIA.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }

  function getOutingsByCategoryXlsx () {
    CompetitionService.getOutingsByCategoryXlsx(competition).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'USCITE PER CATEGORIA.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }

  function saveAdmins () {
    setShowSpinner(true)
    CompetitionService.updateAdmins(competition).then((response) => {
      setShowSpinner(false)
    }).catch((errorResponse) => {
      setShowSpinner(false)
    });
  }

  function assignNumbers () {
    setShowSpinner(true)
    CompetitionService.assignNumbers(competition, startNumber).then((response) => {
      setShowSpinner(false)
    }).catch((errorResponse) => {
      setShowSpinner(false)
    });;
  }

  if (redirect) {
    return <Navigate to={redirect} replace={true}/>
  }
  return (  
    <div className="container">
        { showSpinner &&
            <div className="flex items-center justify-center mt-10 text-center px-6 py-6">
              <Box sx={{ display: 'flex' }}>
                  <CircularProgress />
              </Box>
            </div>
        }
        { competition &&
          <div>
            <Typography gutterBottom variant="h3" component="h2">
              {competition.title}
            </Typography>
            <Grid container spacing={8}>
              <Grid item key="poster" xs={4} sm={4} md={4}>
                <Img alt="complex" src={competition.poster}/>
              </Grid>
              <Grid item key="info" xs={4} sm={4} md={4}>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h5">
                      Nascondi gara: 
                      <Switch
                        checked={competition.hidden}
                        onChange={event => switchActionHidden(event)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </Typography>
                    <Typography variant="h5">
                      Stato iscrizioni: 
                      <Switch
                        checked={competition.enabled}
                        onChange={event => switchAction(event)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </Typography>
                    <Typography variant="h5">
                      Città: {competition.city}
                    </Typography>
                    { !competition.dateEnd && (
                      <Typography>
                        Data: {competition.date}
                      </Typography>
                    )}
                    { competition.dateEnd && (
                      <div>
                        <Typography>
                          Data inizio: {competition.date}
                        </Typography>
                        <Typography>
                          Data fine: {competition.dateEnd}
                        </Typography>
                      </div>
                    )}
                    { competition.registrationEnd && (
                      <Typography>
                          Chiusura iscrizioni: {competition.registrationEnd}
                      </Typography>
                    )}
                  </CardContent>
                  <CompetitionCostsSummary competition={competition}/>
                  {currentUser && currentUser.roles.includes("ROLE_SUPERADMIN") && (
                    <div>
                      <CardActions>
                        <IconButton size="small" onClick={() =>handleClickOpenAdd()}><EditIcon />Modifica Competizione</IconButton>
                      </CardActions>
                      <CardActions>
                        <IconButton size="small" onClick={() =>handleOpenConfirm()}><DeleteIcon />Elimina Competizione</IconButton>
                      </CardActions>
                    </div>
                  )}
                  {competition.rules && (
                    <CardActions>
                      <IconButton size="small" onClick={() =>downloadRules(competition)}><DownloadIcon />Scarica Regolamento</IconButton>
                    </CardActions>
                  )}
                  <CardActions>
                    <IconButton size="small" onClick={() =>goToRegistration()}><EditNoteIcon />Vai alle iscrizioni</IconButton>
                  </CardActions>
              </Grid>
              <Grid item key="info" xs={4} sm={4} md={4}>
                {currentUser && currentUser.roles.includes("ROLE_SUPERADMIN") && (
                  <div>
                    <Autocomplete
                        multiple
                        id="adminsInput"
                        options={allAdmins}
                        value={admins}
                        getOptionLabel={(option) => option.email }
                        onChange={ (e, value) =>{ setAdmins(value); competition.admins = value } }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Aggiungi Amministratore"
                          />
                        )}
                    />
                    <Button size="small" onClick={saveAdmins} disabled={!admins}>Salva</Button>
                  </div>)}
                <TextField
                  margin="dense"
                  id="startNumber"
                  label="Numero di partenza atleti"
                  type="number"
                  fullWidth
                  variant="standard"
                  onChange={ (e) =>setStartNumber(e.target.value)}
                />
                <Button size="small" onClick={assignNumbers} disabled={!startNumber}>Assegna numeri</Button>
                <Button size="small" onClick={getCompetitionRegistrations}>Scarica Elenco Atleti</Button>
                <Button size="small" onClick={getAssociationsRegistrationsPdf}>Scarica Associazioni Iscritte PDF</Button>
                <Button size="small" onClick={getAssociationsRegistrationsXlsx}>Scarica Associazioni Iscritte XLSX</Button>
                <Button size="small" onClick={getPilotsPdf}>Scarica Fogli Pilota PDF</Button>
                <Button size="small" onClick={getPilotsXlsx}>Scarica Fogli Pilota XLSX</Button>
                <Button size="small" onClick={getOutingsPdf}>Scarica Elenco Uscite PDF</Button>
                <Button size="small" onClick={getOutingsXlsx}>Scarica Elenco Uscite XLSX</Button>
                <Button size="small" onClick={getOutingsByAssociationPdf}>Scarica Uscite per associazione PDF</Button>
                <Button size="small" onClick={getOutingsByAssociationXlsx}>Scarica Uscite per associazione XLSX</Button>
                <Button size="small" onClick={getOutingsByCategoryPdf}>Scarica Uscite per categoria PDF</Button>
                <Button size="small" onClick={getOutingsByCategoryXlsx}>Scarica Uscite per categoria XLSX</Button>
              </Grid>
              <Grid item key="discipline" xs={12} sm={12} md={12}>
                <CompetitionGrid competitionId={params.competitionId} elements={elements} refreshElements={refreshCompetition}/>
              </Grid>
            </Grid>
            <AddCompetitionDialog
              open={openAdd}
              handleClose={() => handleClose()}
              refreshCompetitions={refreshCompetition}
              competition={competition}
            />
            <ConfirmDialog
              open={openConfirm}
              handleClose={handleCloseConfirm}
              confirmAction={handleConfirmAction}
              title={"Conferma cancellazione"}
              message={"Sei sicuro di procedere con la cancellazione della competizione?"}
            />
          </div>
        }
    </div>
  );

}