import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import DisciplineService from "../services/discipline-service";
import SportClassService from "../services/class-service";
import CategoryService from "../services/category-service";
import UnitService from "../services/unit-service";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default class AddElementDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    	id: null,
      name: '',
      min: null,
      max: null,
      maxOuters: null,
      choreographyName: false,
      showSpinner: false
    };
  }

	componentDidUpdate(prevProps, prevState) {
		if( this.props.element.id !== prevProps.element.id 
			|| this.props.element.name !== prevProps.element.name 
			|| this.props.element.min !== prevProps.element.min
			|| this.props.element.max !== prevProps.element.max
			|| this.props.element.maxOuters !== prevProps.element.maxOuters
			|| this.props.element.choreographyName !== prevProps.element.choreographyName){
			this.setState({
				id: this.props.element.id,
		      	name: this.props.element.name,
		      	min: this.props.element.min,
		      	max: this.props.element.max,
		      	maxOuters: this.props.element.maxOuters,
		      	choreographyName: this.props.element.choreographyName,
		      	showSpinner: false
			})
		}
	}
  
  addElement = async e => {
    e.preventDefault();
    this.setState({showSpinner: true})
    var elementToCreate={
    	id: this.state.id ? this.state.id: null,
      	name: this.state.name,
      	min: this.state.min ? this.state.min: null,
      	max: this.state.max ? this.state.max: null,
      	maxOuters: this.state.maxOuters ? this.state.maxOuters: null,
      	choreographyName: this.state.choreographyName ? this.state.choreographyName: null
    }

    var elementService
	switch(this.props.type) {
	    case 'discipline':
	    	elementService = DisciplineService;
	    	break;
	    case 'category':
	    	elementService = CategoryService;
	    	break;
	    case 'sportClass':
	    	elementService = SportClassService;
	    	break;
	    case 'units':
	    	elementService = UnitService;
	    	break;
	    default:
	    	elementService = null
	}

	if(elementService){
		elementService.add(elementToCreate).then(
	        response => {
	            this.setState({showSpinner: false})
	    		this.props.handleClose();
				this.props.refreshElements();
	        },
	        error => {
	          this.setState({showSpinner: false})
	    		this.props.handleClose();
				this.props.refreshElements();
	        }
	    );		
	}

  }  
  
  render(){
	  return (
	      <Dialog
	        open={this.props.open}
	        aria-labelledby="alert-dialog-title"
	        aria-describedby="alert-dialog-description"
	      >
	        <DialogTitle id="alert-dialog-title">
	          <b>{this.props.title}</b>
	        </DialogTitle>
	        <DialogContent>
	          <TextField
	            autoFocus
	            margin="dense"
	            id="name"
	            label="Nome"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.name}
	            onChange={ (e) =>{ this.setState({ name: e.target.value });}}
	          />
	          { this.props.type == "category" && (
		          <TextField
		            margin="dense"
		            id="min"
		            label="Età Minima"
		            type="number"
		            fullWidth
		            variant="standard"
		            value={this.state.min}
		            onChange={ (e) =>{ this.setState({ min: e.target.value });}}
		          />       	
		      )}
	          { this.props.type == "category" && (
		          <TextField
		            margin="dense"
		            id="max"
		            label="Età Massima"
		            type="number"
		            fullWidth
		            variant="standard"
		            value={this.state.max}
		            onChange={ (e) =>{ this.setState({ max: e.target.value });}}
		          />        	
		      )}
	          { this.props.type == "discipline" && (
		          <TextField
		            margin="dense"
		            id="max"
		            label="Numero fuoriquota"
		            type="number"
		            fullWidth
		            variant="standard"
		            value={this.state.max}
		            onChange={ (e) =>{ this.setState({ max: e.target.value });}}
		          />        	
		      )}
		      { this.props.type == "discipline" && (
		        <FormControlLabel 
		          	control={<Checkbox
					  checked={this.state.choreographyName}
					  onChange={(event) =>{ 
			          	this.setState({ 
			          		choreographyName: event.target.checked
			          	});
			          }}
					  inputProps={{ 'aria-label': 'controlled' }}
				  		/>
				  	} 
				  	label="Richiesto nome coreografia" />     	
		      )}
		      { this.props.type == "category" && (
		          <TextField
		            margin="dense"
		            id="maxOuters"
		            label="Età Massima Fuoriquota"
		            type="number"
		            fullWidth
		            variant="standard"
		            value={this.state.maxOuters}
		            onChange={ (e) =>{ this.setState({ maxOuters: e.target.value });}}
		          />        	
		      )}
	        </DialogContent>
	        <DialogActions>
	          <Button onClick={this.props.handleClose} disabled={this.state.showSpinner}>Chiudi</Button>
	          <Button onClick={this.addElement} disabled={this.state.showSpinner}>Aggiungi</Button>
	          {
	                this.state.showSpinner &&
			    	<Box sx={{ marginLeft:'5px', display: 'flex' }}>
			      		<CircularProgress />
			    	</Box>
	          }
	        </DialogActions>
	      </Dialog>
	  );
  }
}