import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import RegistrationService from "../services/registration-service";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import AuthService from "../services/auth-service";
import UserService from "../services/user-service";
import Stack from '@mui/material/Stack';
import { Autocomplete } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

function getAge (birthDate){
	var parts = birthDate.split('/');
	var dob = new Date(parts[2], parts[1] - 1, parts[0]); 
	var month_diff = Date.now() - dob.getTime();  
	var age_dt = new Date(month_diff);   
	var year = age_dt.getUTCFullYear();  
	var age = Math.abs(year - 1970);
	return age;
}

export default class RegistrationDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    	categories:[],
    	classes:[],
    	units:[],
      	discipline: null,
      	choreographyName:null,
      	category: null,
      	class: null,
      	unit: null,
      	gymnasts: [],
      	availableGymnasts: this.props.gymnasts,
      	minSelection: 1,
      	maxSelection: 100,
	    isAdmin: false,
	    currentUser:'',
	    associations:[],
	    association: '',
	    checkOut: false,
      	showSpinner: false,
      	number: null,
      	emptyValues: false,
      	changedValues: 0,
      	openSnack: false
    };
  }

  componentDidUpdate() {
  	var differencesNumber = 0;
  	if(this.props.registrationToEdit && this.props.registrationToEdit.discipline.id != this.state.discipline) differencesNumber++;
  	if(this.props.registrationToEdit && this.props.registrationToEdit.choreographyName != this.state.choreographyName) differencesNumber++;
  	if(this.props.registrationToEdit && this.props.registrationToEdit.category.id != this.state.category) differencesNumber++;
  	if(this.props.registrationToEdit && this.props.registrationToEdit.sportClass.id != this.state.class) differencesNumber++;
  	if(this.props.registrationToEdit && this.props.registrationToEdit.number != this.state.number) differencesNumber++;
  	if(this.props.registrationToEdit && this.props.registrationToEdit.unit.id != this.state.unit) differencesNumber++;
  	if(this.props.registrationToEdit && this.props.registrationToEdit.association && this.props.registrationToEdit.association.name != this.state.association) differencesNumber++;
  	if(this.props.registrationToEdit && differencesNumber - this.state.changedValues > 1){
  		this.setState({
  			association: this.props.registrationToEdit.association ? this.props.registrationToEdit.association.name: this.state.association.name,
  			discipline: this.props.registrationToEdit.discipline.id,
  			choreographyName: this.props.registrationToEdit.choreographyName,
  			number: this.props.registrationToEdit.number,
  			changedValues: this.state.changedValues+1
  		}, () =>{
  			this.updateValues(this.state.discipline)
  			if(this.state.association){
  				this.updateGymnasts()
  			}
  			this.setState({
  				unit: this.props.registrationToEdit.unit.id,
  				category: this.props.registrationToEdit.category.id,
	  			class: this.props.registrationToEdit.sportClass.id,
	  			gymnasts: this.props.registrationToEdit.gymnasts
  			})
  		})
  	}else if(this.state.emptyValues){
  		this.setState({
  			association: '',
  			discipline: null,
	      	category: null,
	      	class: null,
	      	unit: null,
  			emptyValues: false,
  			number: null,
  			choreographyName: null,
  			gymnasts: [],
  			changedValues: 0
  		})
  	}
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();
    this.setState({
    	currentUser: user,
    	association: user
  	});
    if (user && (user.roles.includes("ROLE_SUPERADMIN")|| user.roles.includes("ROLE_ADMIN"))) {
		UserService.getAll().then(
	        response => {
		      	this.setState({
		        	associations: response.data,
		        	isAdmin: true,
		        	availableGymnasts: []
		      	});
	        },
	        error => {
		      	console.log(error)
	        }
	    );
    }
    if(this.props.matches){
    	this.props.matches.sort((a,b) => (a.disciplines[0].name > b.disciplines[0].name) ? 1 : ((b.disciplines[0].name > a.disciplines[0].name) ? -1 : 0))
    }
  }

  updateValues = (discipline) => {
    var selectedMatch = this.props.matches.find( x => x.disciplines[0].id === discipline)
    this.setState({
    	units: selectedMatch.units
    })
    if(selectedMatch && this.state.unit){
    	var selectedUnit = selectedMatch.units.find( x => x.id === this.state.unit)
    	if(selectedUnit.name.toUpperCase() == "SINGOLO" ){
    		this.setState({
    			minSelection:1,
    			maxSelection: 1
    		})
    	}else if(selectedUnit.name.toUpperCase() == "DOPPIO"){
    		this.setState({
    			minSelection:2,
    			maxSelection: 2
    		})
    	}else{
    		this.setState({
    			minSelection:3,
    			maxSelection: 99
    		})
    	}
    }
    if(selectedMatch && this.state.gymnasts){
    	var availableCategories = selectedMatch.categories
    	/*this.state.gymnasts.forEach(function(gymnast){
		    var age = getAge(gymnast.birthDate)
		    availableCategories = availableCategories.filter( x => (x.min <= age  && age <= x.max))
    	})*/
    	this.setState({
	    	categories: availableCategories.length > 0 ? availableCategories:[],
	    	classes: selectedMatch.sportClasses
	    })
    }
   	this.checkOuters()
  };



  updateGymnasts = () => {
  	var available = this.props.gymnasts.filter( x => x.association == this.state.association)
  	this.setState({
  		gymnasts: [],
  		availableGymnasts: available
  	})
  }

  checkOuters = () => {
  	var selectedMatch = this.props.matches.find( x => x.disciplines[0].id === this.state.discipline)
  	if(selectedMatch){
  		var selectedDiscipline = selectedMatch.disciplines[0]
	  	var selectedCategory = selectedMatch.categories.find( x => x.id === this.state.category)
	  	if(selectedDiscipline && selectedCategory && selectedDiscipline.max && selectedCategory.maxOuters){
	  		var outers = 0;
	  		const scope = this
	  		var notPossible = false
	  		for (var gymnast of this.state.gymnasts) {  
				var age = getAge(gymnast.birthDate)
		  		if(age > selectedCategory.maxOuters){
		  			notPossible = true
		  			break;
		  		}else if(age > selectedCategory.max && age <= selectedCategory.maxOuters){
		  			outers++;
		  		}
			}
		  	/*this.state.gymnasts.every(gymnast => {
		  		var age = getAge(gymnast.birthDate)
		  		if(age > selectedCategory.maxOuters){
		  			notPossible = true
		  			return false;
		  		}else if(age > selectedCategory.max && age <= selectedCategory.maxOuters){
		  			outers++;
		  		}
		  	})*/
		  	if(notPossible){
		  		scope.setState({
	  				checkOut: true
	  			})
	  			return;
		  	}
		  	if(outers > ((this.state.gymnasts.length-outers)/selectedDiscipline.max)){
		  		scope.setState({
	  				checkOut: true
	  			})
		  	}else{
		  		scope.setState({
	  				checkOut: false
	  			})		  		
		  	}
	  	}else if(selectedDiscipline && selectedCategory && !selectedDiscipline.max){
	  		this.setState({
  				checkOut: false
  			})	
	  	}
  	}
  }
  
  newRegistration = async e => {
    e.preventDefault();
    this.setState({showSpinner: true})
    var selectedMatch = this.props.matches.find( x => x.disciplines[0].id === this.state.discipline)
    var selectedDiscipline = selectedMatch.disciplines.find( x => x.id === this.state.discipline)
    var selectedCategory = selectedMatch.categories.find( x => x.id === this.state.category)
    var selectedClass = selectedMatch.sportClasses.find( x => x.id === this.state.class)
    var selectedUnit = selectedMatch.units.find( x => x.id === this.state.unit)
    var selectedAssociation = this.state.associations.length > 0 ? this.state.associations.find( x => x.name === this.state.association) : AuthService.getCurrentUser()
    var newRegistration={
    	competitionId: this.props.competitionId,
      	discipline: selectedDiscipline,
      	category: selectedCategory,
        sportClass: selectedClass,
        unit: selectedUnit,
        number: this.state.number,
        choreographyName: this.state.choreographyName,
        gymnasts: this.state.gymnasts,
        association: selectedAssociation
    }

    if(this.props.registrationToEdit){
    	newRegistration.id = this.props.registrationToEdit.registrationId
		RegistrationService.edit(newRegistration).then(
	        response => {
	            this.setState({
	            	showSpinner: false,
	            	openSnack: true,
	            	emptyValues:true
	            })
	    		this.props.handleClose();
				this.props.refreshRegistrations();
	        },
	        error => {
	          this.setState({
	            	showSpinner: false,
	            	emptyValues:true
	            })
	    		this.props.handleClose();
				this.props.refreshRegistrations();
	        }
	    );
    }else{
    	RegistrationService.add(newRegistration).then(
	        response => {
	            this.setState({
	            	showSpinner: false,
	            	openSnack: true,
	            	emptyValues:true
	            })
	    		this.props.handleClose();
				this.props.refreshRegistrations();
	        },
	        error => {
	          this.setState({
	            	showSpinner: false,
	            	emptyValues:true
	            })
	    		this.props.handleClose();
				this.props.refreshRegistrations();
	        }
	    );
    }

  }  
  
  render(){
	  return (
	  	<div>
	      <Dialog
	        open={this.props.open}
	        aria-labelledby="alert-dialog-title"
	        aria-describedby="alert-dialog-description"
	      >
	        <DialogTitle id="alert-dialog-title">
	          <b>Nuova Iscrizione</b>
	        </DialogTitle>
	        <DialogContent>
		        <Stack spacing={2}>
		        	{this.state.isAdmin && (
		        	<div>
		        		<FormControl fullWidth>
						  <InputLabel id="associationInputLabel">Associazione</InputLabel>
						  <Select
						    labelId="associationInputLabel"
						    id="associationInput"
						    value={this.state.association}
						    label="Associazione"
						    onChange={ (e) =>{ this.setState({ association: e.target.value },() => {this.updateGymnasts()}) }}
						  >
						    {this.state.associations.map((data) => (
							  <MenuItem key={data.username} value={data.name}>{data.name}</MenuItem>
							))}
						  </Select>
						</FormControl>
						<TextField
				            margin="dense"
				            id="number"
				            label="Numero Iscrizione"
				            type="text"
				            fullWidth
				            variant="standard"
				            value={this.state.number}
				            onChange={ (e) =>{ this.setState({ number: e.target.value });}}
				          />
				    </div>
					)}
		        	<FormControl fullWidth>
					  <InputLabel id="disciplineInputLabel">Disciplina</InputLabel>
					  <Select
					    labelId="disciplineInputLabel"
					    id="disciplineInput"
					    value={this.state.discipline}
					    label="Disciplina"
					    onChange={ (e) =>{ this.setState({ discipline: e.target.value }); this.updateValues(e.target.value);}}
					  >
					    {this.props.matches.map((data) => (
						  <MenuItem key={data.disciplines[0].id} value={data.disciplines[0].id}>{data.disciplines[0].name}</MenuItem>
						))}
					  </Select>
					</FormControl>
					{this.props.matches.find( x => x.disciplines[0].id === this.state.discipline) && this.props.matches.find( x => x.disciplines[0].id === this.state.discipline).disciplines[0].choreographyName && (
						<TextField
					            margin="dense"
					            id="choreographyName"
					            label="Titolo coreografia"
					            type="text"
					            fullWidth
					            variant="standard"
					            value={this.state.choreographyName}
					            onChange={ (e) =>{ this.setState({ choreographyName: e.target.value });}}
					          />
					)}
		        	<FormControl fullWidth>
					  <InputLabel id="unitInputLabel">Unità Competitiva</InputLabel>
					  <Select
					    labelId="unitInputLabel"
					    id="unitInput"
					    value={this.state.unit}
					    label="Unità Competitiva"
					    onChange={ (e) =>{ this.setState({ unit: e.target.value }, () => {this.updateValues(this.state.discipline);}) }}
					  >
					    {this.state.units.map((data) => (
						  <MenuItem key={data.id} value={data.id}>{data.name}</MenuItem>
						))}
					  </Select>
					</FormControl>
		        	<Autocomplete
				        multiple
				        id="gymnastInput"
				        value={this.state.gymnasts}
				        options={this.state.availableGymnasts}
				        getOptionLabel={(option) => option.name+' '+option.surname+' ('+option.birthDate+')'}
				        onChange={ (e, value) =>{ this.setState({ gymnasts: value }, () => {this.updateValues(this.state.discipline);}) }}
				        getOptionDisabled={(options) => (this.state.gymnasts.length >= this.state.maxSelection ? true : false)}
				        renderInput={(params) => (
				          <TextField
				            {...params}
				            variant="standard"
				            label="Selezione atleti"
				          />
				        )}
				    />
				    { this.state.checkOut && (<Alert severity="error">Attenzione! Verifica fuoriquota selezionati!</Alert> )}
					<FormControl fullWidth>
					  <InputLabel id="categoryInputLabel">Categoria</InputLabel>
					  <Select
					    labelId="categoryInputLabel"
					    id="categoryInput"
					    value={this.state.category}
					    label="Categoria"
					    onChange={ (e) =>{ this.setState({ category: e.target.value }, () => {this.checkOuters();});}}
					  >
					    {this.state.categories.map((data) => (
						  <MenuItem key={data.id} value={data.id}>{data.name}</MenuItem>
						))}
					  </Select>
					</FormControl>	
					<FormControl fullWidth>
					  <InputLabel id="classInputLabel">Classe/Attrezzo</InputLabel>
					  <Select
					    labelId="classInputLabel"
					    id="classInput"
					    value={this.state.class}
					    label="Classe/Attrezzo"
					    onChange={ (e) =>{ this.setState({ class: e.target.value });}}
					  >
					    {this.state.classes.map((data) => (
						  <MenuItem key={data.id} value={data.id}>{data.name}</MenuItem>
						))}
					  </Select>
					</FormControl>	
				</Stack>		
	        </DialogContent>
	        <DialogActions>
	          <Button onClick={() =>{this.props.handleClose(); this.setState({emptyValues: true})}} disabled={this.state.showSpinner}>Chiudi</Button>
	          <Button onClick={this.newRegistration} disabled={this.state.showSpinner || this.state.checkOut || !this.state.discipline || !this.state.category || !this.state.class || !this.state.unit || this.state.gymnasts.length < this.state.minSelection || this.state.gymnasts.length > this.state.maxSelection}>Invia</Button>
	          {
	                this.state.showSpinner &&
			    	<Box sx={{ marginLeft:'5px', display: 'flex' }}>
			      		<CircularProgress />
			    	</Box>
	          }
	        </DialogActions>
	      </Dialog>
			<Snackbar
		        open={this.state.openSnack}
		        autoHideDuration={3000}
		        onClose={(event, reason) => {
		        	if (reason === 'clickaway') {
				      return;
				    }
				    this.setState({
				    	openSnack:false
				    })
				}}
		        message="Registrazione avvenuta con successo."
		      />
		</div>
	  );
  }
}