import React from "react";
import JudgesGrid from '../components/judges-grid-component';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import JudgeService from "../services/judge-service";
import AuthService from "../services/auth-service";
import { Navigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Logo from '../images/sem_logo.png';
import Typography from '@mui/material/Typography';


class Help extends React.Component {
    constructor(props) {
        super(props);
    }
	
	render(){
	  	return (
	  		<div>
			  	<Card
		              sx={{ maxWidth: 600, margin: "auto", marginBottom: 2 }}
		            >
		              <CardMedia
		                component="img"
		                component='img' src={Logo}
		              />
		              <CardContent sx={{ flexGrow: 1 }}>
		                <Typography gutterBottom variant="h5" component="h2">
		                  Contatti
		                </Typography>
		                <Typography>
		                  Email: info@sporteventmanagement.it
		                </Typography>
		                <Typography>
		                  Telefono: +39 3498745658
		                </Typography>
		                <Typography>
		                  Responsabile: Fabio Gencarelli
		                </Typography>
		              </CardContent>
	            </Card>
	        </div>
	  );
	}
}

export default Help;