import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import UserService from "../services/user-service";

export default class AddCompetitionAdminDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      showSpinner: false
    };
  }
  
  addAdmin = async e => {
    e.preventDefault();
    this.setState({showSpinner: true})
    var adminToCreate={
      	email: this.state.email,
      	password: this.state.password,
    }

	UserService.addAdmin(adminToCreate).then(
        response => {
            this.setState({showSpinner: false})
    		this.props.handleClose();
			this.props.refreshAdmins();
        },
        error => {
          this.setState({showSpinner: false})
    		this.props.handleClose();
			this.props.refreshAdmins();
        }
    );
  }  
  
  render(){
	  return (
	      <Dialog
	        open={this.props.open}
	        aria-labelledby="alert-dialog-title"
	        aria-describedby="alert-dialog-description"
	      >
	        <DialogTitle id="alert-dialog-title">
	          <b>Aggiungi Amministratore</b>
	        </DialogTitle>
	        <DialogContent>
	          <TextField
	            autoFocus
	            margin="dense"
	            id="email"
	            label="Email"
	            type="text"
	            fullWidth
	            variant="standard"
	            onChange={ (e) =>{ this.setState({ email: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="password"
	            label="Password"
	            type="password"
	            fullWidth
	            variant="standard"
	            onChange={ (e) =>{ this.setState({ password: e.target.value });}}
	          />
	        </DialogContent>
	        <DialogActions>
	          <Button onClick={this.props.handleClose} disabled={this.state.showSpinner}>Chiudi</Button>
	          <Button onClick={this.addAdmin} disabled={this.state.showSpinner}>Aggiungi</Button>
	          {
	                this.state.showSpinner &&
			    	<Box sx={{ marginLeft:'5px', display: 'flex' }}>
			      		<CircularProgress />
			    	</Box>
	          }
	        </DialogActions>
	      </Dialog>
	  );
  }
}