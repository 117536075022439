import * as React from 'react';
import { DataGrid, itIT } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import { GridToolbarContainer } from '@mui/x-data-grid'; 
import CustomGridLoadingOverlay from './custom-grid-loading-overlay-component';
import AddRowElementDialog from './add-row-element-grid-component';
import ConfirmDialog from './confirm-dialog-component';
import CompetitionService from "../services/competition-service";


function ActionsButton(props) {
  const { deleteAction, editAction } = props;
  
  return (
  	<Grid container direction="row" alignItems="center">
		<IconButton onClick={editAction}><EditIcon /></IconButton>
	    <IconButton onClick={deleteAction}><DeleteIcon /></IconButton>
	</Grid>
  );
}

 function CustomToolbar(props) {
  const { addAction } = props;
  return (
    <GridToolbarContainer>
      <Grid container justifyContent="flex-end">
	      <Button variant="contained" endIcon={<AddIcon />} onClick={addAction}>
	        Aggiungi
	      </Button>
	  </Grid>
    </GridToolbarContainer>
  );
}

export default class CompetitionGrid extends React.Component {

  	constructor(props) {
	    super(props);
	    this.state = {
	      open: false,
	      openConfirm: false,
	      openEdit: false,
	      rowToDelete: '',
	      rowToEdit: null
	    };
  	}

  handleClickOpenConfirm = () => {
    this.setState({openConfirm: true})
  };

  handleCloseConfirm = () => {
    this.setState({openConfirm: false})
  };
  
  handleConfirmAction = () => {
    this.setState({openConfirm: false})
  };
  
  handleConfirmAction = async e => {
    e.preventDefault();
    CompetitionService.deleteMatch({
    	id: this.state.rowToDelete.matchId
    }).then(
      response => {
        this.handleCloseConfirm();
		this.props.refreshElements();
      },
      error => {
        this.handleCloseConfirm();
		this.props.refreshElements();
      }
    );
  } 
  
  handleClickOpen = () => {
    this.setState({open: true})
  };

  handleClose = () => {
    this.setState({
    	open: false,
    	rowToEdit: null
    })
  };

  handleCloseEdit = () => {
    this.setState({openEdit: false})
  };
  
  columns = [
	  {
	    field: 'discipline',
	    headerName: 'Disciplina',
	    flex: 1,
	    editable: false,
	    valueGetter: (params) => {
	    	var disciplines = params.row.discipline.map(function(item) {
	          return item['name'];
	        });
	    	return disciplines
	    }
	  },
	  {
	    field: 'category',
	    headerName: 'Categoria',
	    flex: 1,
	    editable: false,
	    valueGetter: (params) => {
	    	var categories = params.row.category.map(function(item) {
	          return item['name'] +' ('+item['min']+'/'+item['max']+')';
	        });
	    	return categories
	    }
	  },
	  {
	    field: 'sportClass',
	    headerName: 'Classe/Attrezzo',
	    editable: false,
	    flex: 1,
	    valueGetter: (params) => {
	    	var sportClasses = params.row.sportClass.map(function(item) {
	          return item['name'];
	        });
	    	return sportClasses
	    }
	  },
	  {
	    field: 'unit',
	    headerName: 'Unità competitiva',
	    editable: false,
	    flex: 1,
	    valueGetter: (params) => {
	    	var units = params.row.unit.map(function(item) {
	          return item['name'];
	        });
	    	return units
	    }
	  },
	  {
	    field: 'actions',
	    headerName: 'Azioni',
	    width: 100,
	    editable: false,
	    renderCell: (params) => {
	      	const editAction = (e) => {
	        	e.stopPropagation();
	        	this.setState({
	        		rowToEdit: params.row,
		        	open: true
		        })

	      	};
	      
	      	const deleteAction = (e) => {
		        e.stopPropagation();
		        this.setState({
		        	openConfirm :true,
		        	rowToDelete: params.row
		        })
	      	};
	
	      	return <ActionsButton editAction={editAction} deleteAction={deleteAction}/>;
	    }
	  },
	];
  
  render(){
	  return (
	    <div style={{ height: 450, width: '100%' }}>
	      <DataGrid
	      	localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
	        rows={this.props.elements}
	        columns={this.columns}
	        pageSize={5}
	        rowsPerPageOptions={[5]}
	        disableSelectionOnClick
	        loading={this.props.elements.length===0}
	        sx={{ marginBottom:'15px'}}
			components={{
	          Toolbar: CustomToolbar,
	          LoadingOverlay: CustomGridLoadingOverlay,
	        }}
	        componentsProps={{
	          toolbar: {addAction:this.handleClickOpen}
	        }}
	      />
	      <AddRowElementDialog
	        competitionId={this.props.competitionId}
	      	open={this.state.open}
	        handleClose={this.handleClose}
	        refreshElements={this.props.refreshElements}
	        rowToEdit={this.state.rowToEdit}
	      />
	      <ConfirmDialog
	      	open={this.state.openConfirm}
	        handleClose={this.handleCloseConfirm}
	        confirmAction={this.handleConfirmAction}
	        title={"Conferma cancellazione"}
	        message={"Sei sicuro di procedere con la cancellazione?"}
	      />
	    </div>
	  );
  }
}