import Box from "@mui/material/Box";
import PropTypes from 'prop-types';
import { GridFooterContainer, GridFooter } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';

function CustomFooterTotalComponent(props) {
  return (
    <GridFooterContainer>
      <Box sx={{ padding: "10px", display: "flex" }}>
        <Typography variant="h5">
      		Totale: {props.total}€
        </Typography>
      </Box>
      <GridFooter sx={{
        border: 'none', // To delete double border.
        }} />
    </GridFooterContainer>
  );
}

CustomFooterTotalComponent.propTypes = {
  total: PropTypes.number
};

export { CustomFooterTotalComponent };