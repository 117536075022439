import { GridOverlay} from '@mui/x-data-grid'; 
import LinearProgress from '@mui/material/LinearProgress';

export default function CustomGridLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}