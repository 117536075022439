import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import GymnastService from "../services/gymnast-service";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import dayjs from 'dayjs';

export default class EditGymnastDialog extends React.Component {

  	constructor(props) {
	    super(props);
	    this.state = {
	      gymnastId: this.props.gymnast.gymnastId,
	      name: this.props.gymnast.name,
	      surname: this.props.gymnast.surname,
	      csenCode: this.props.gymnast.csenCode,
	      birthDate: this.props.gymnast.birthDate,
	      birthCity: this.props.gymnast.birthCity,
	      cf: this.props.gymnast.cf,
	      genre: this.props.gymnast.genre,
	      showSpinner: false
	    };
  	}

	componentDidUpdate(prevProps, prevState) {
		if( this.props.gymnast.name !== prevProps.gymnast.name 
			|| this.props.gymnast.surname !== prevProps.gymnast.surname
			|| this.props.gymnast.csenCode !== prevProps.gymnast.csenCode
			|| this.props.gymnast.birthDate !== prevProps.gymnast.birthDate
			|| this.props.gymnast.birthCity !== prevProps.gymnast.birthCity
			|| this.props.gymnast.cf !== prevProps.gymnast.cf
			|| this.props.gymnast.genre !== prevProps.gymnast.genre){
			this.setState({
				gymnastId: this.props.gymnast.gymnastId,
		      	name: this.props.gymnast.name,
		      	surname: this.props.gymnast.surname,
		      	csenCode: this.props.gymnast.csenCode,
		      	birthDate: this.props.gymnast.birthDate,
		      	birthCity: this.props.gymnast.birthCity,
		      	cf: this.props.gymnast.cf,
		      	genre: this.props.gymnast.genre,
		      	showSpinner: false
			})
		}
	}

  	editGymnast = async e => {
	    e.preventDefault();
	    this.setState({showSpinner: true})
	    var gymnastToEdit={
	    	gymnastId: this.state.gymnastId,
	      	name: this.state.name,
	      	surname: this.state.surname,
	        csenCode: this.state.csenCode,
	        birthDate: this.state.birthDate,
		    birthCity: this.state.birthCity,
		    cf: this.state.cf,
		    genre: this.state.genre
	    }
	    
		GymnastService.editGymnast(gymnastToEdit).then(
	        response => {
	            this.setState({showSpinner: false})
	    		this.props.handleClose();
				this.props.refreshGymnasts();
	        },
	        error => {
	          this.setState({showSpinner: false})
	    		this.props.handleClose();
				this.props.refreshGymnasts();
	        }
	    );
  	}  
  
  	render(){
	  return (
	      <Dialog
	        open={this.props.open}
	        aria-labelledby="alert-dialog-title"
	        aria-describedby="alert-dialog-description"
	      >
	        <DialogTitle id="alert-dialog-title">
	          <b>Modifica Atleta</b>
	        </DialogTitle>
	        <DialogContent>
	          <TextField
	            margin="dense"
	            id="name"
	            label="Nome"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.name}
	            onChange={ (e) =>{ this.setState({ name: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="surname"
	            label="Cognome"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.surname}
	            onChange={ (e) =>{ this.setState({ surname: e.target.value });}}
	          />
	          <LocalizationProvider dateAdapter={AdapterDayjs}>
	             	<DemoContainer components={['DatePicker']}>
		          		<DatePicker
			          	  id="birthDate"
				          label="Data di nascita"
				          inputFormat="DD/MM/YYYY"
				          format="DD/MM/YYYY"
				          value={dayjs(this.state.birthDate, "DD/MM/YYYY")}
				          onChange={(newValue) =>{ 
				          	this.setState({ birthDate: newValue.format("DD/MM/YYYY") });
				          }}
			        	/>
			        </DemoContainer>
			  </LocalizationProvider>
	          <TextField
	            margin="dense"
	            id="birthCity"
	            label="Luogo di nascita"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.birthCity}
	            onChange={ (e) =>{ this.setState({ birthCity: e.target.value });}}
	          />
	          <TextField
	            margin="dense"
	            id="cf"
	            label="Codice Fiscale"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.cf}
	            onChange={ (e) =>{ this.setState({ cf: e.target.value });}}
	          />
	          <TextField
	          	autoFocus
	            margin="dense"
	            id="csenCode"
	            label="Codice Tessera"
	            type="text"
	            fullWidth
	            variant="standard"
	            value={this.state.csenCode}
	            onChange={ (e) =>{ this.setState({ csenCode: e.target.value });}}
	          />
	          <FormControl fullWidth>
				  <InputLabel id="genre-label">Genere</InputLabel>
				  <Select
				    labelId="genre-label"
				    id="genre"
				    value={this.state.genre}
				    label="Genere"
				    onChange={(e) =>{ this.setState({ genre: e.target.value });} }
				  >
				    <MenuItem value="M">Maschio</MenuItem>
				    <MenuItem value="F">Femmina</MenuItem>
				  </Select>
				</FormControl>
	        </DialogContent>
	        <DialogActions>
	          <Button onClick={this.props.handleClose} disabled={this.state.showSpinner}>Chiudi</Button>
	          <Button onClick={this.editGymnast} disabled={this.state.showSpinner}>Modifica</Button>
	          {
	                this.state.showSpinner &&
			    	<Box sx={{ marginLeft:'5px', display: 'flex' }}>
			      		<CircularProgress />
			    	</Box>
	          }
	        </DialogActions>
	      </Dialog>
	  );
  }
}